export const boxShadow = {
  low: `0px 0.6px 0.8px hsl(var(--shadow-color) / 0.06),
        0px 1px 1.4px -0.4px hsl(var(--shadow-color) / 0.14),
        0px 2px 2.8px -0.7px hsl(var(--shadow-color) / 0.23);`,
  medium: `0px 0.6px 0.8px hsl(var(--shadow-color) / 0.05),
        0px 1.9px 2.6px -0.2px hsl(var(--shadow-color) / 0.09),
        0px 3.3px 4.6px -0.4px hsl(var(--shadow-color) / 0.13),
        0px 5.7px 7.9px -0.6px hsl(var(--shadow-color) / 0.18),
        0px 9.9px 13.7px -0.7px hsl(var(--shadow-color) / 0.22);`,
  high: `0px 0.6px 0.8px hsl(var(--shadow-color) / 0.04),
        0px 2.9px 4px -0.1px hsl(var(--shadow-color) / 0.06),
        0px 4.8px 6.7px -0.2px hsl(var(--shadow-color) / 0.08),
        0px 6.8px 9.4px -0.2px hsl(var(--shadow-color) / 0.1),
        0px 9.1px 12.6px -0.3px hsl(var(--shadow-color) / 0.12),
        0px 12px 16.7px -0.4px hsl(var(--shadow-color) / 0.14),
        0px 15.8px 21.9px -0.5px hsl(var(--shadow-color) / 0.16),
        -0.1px 20.8px 28.9px -0.6px hsl(var(--shadow-color) / 0.18),
        -0.1px 27.2px 37.7px -0.7px hsl(var(--shadow-color) / 0.2),
        -0.1px 35.3px 49px -0.7px hsl(var(--shadow-color) / 0.22);`,
};

export const dropShadow = {
  low: `
    drop-shadow(0px 0.5px 0.8px hsl(var(--shadow-color) / 0.17)) 
    drop-shadow(0px 1.1px 1.7px hsl(var(--shadow-color) / 0.33))`,
  medium: `
    drop-shadow(0px 1.3px 2px hsl(var(--shadow-color) / 0.13)) 
    drop-shadow(0px 2.6px 3.9px hsl(var(--shadow-color) / 0.26)) 
    drop-shadow(0px 5.3px 8px hsl(var(--shadow-color) / 0.32))`,
  high: `
    drop-shadow(0px 2.7px 4.1px hsl(var(--shadow-color) / 0.08)) 
    drop-shadow(0px 7.2px 10.8px hsl(var(--shadow-color) / 0.24)) 
    drop-shadow(-0.1px 10.5px 15.8px hsl(var(--shadow-color) / 0.32)) 
    drop-shadow(-0.1px 22.3px 33.5px hsl(var(--shadow-color) / 0.47))`,
};
