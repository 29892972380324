import { createContext, useEffect, useRef, useContext, useMemo, Reducer } from 'react';
import { v4 as uuid } from 'uuid';
import { useEffectOnce } from 'hooks';

export const lockBody = () => {
  const top = window.scrollY;
  const scrollBarCompensation = window.innerWidth - document.body.offsetWidth;

  if (top) {
    document.body.style.top = `-${window.scrollY}px`;
  }

  document.body.classList.add('no-scroll');
  document.body.style.paddingRight = `${scrollBarCompensation}px`;
  document.body.style.position = 'fixed';
  document.body.style.setProperty('--scrollbar-compensation', `${scrollBarCompensation}px`);
};

export const unlockBody = () => {
  const top = -parseInt(document.body.style.top || '0');

  document.body.classList.remove('no-scroll');
  document.body.style.removeProperty('position');
  document.body.style.removeProperty('top');
  document.body.style.removeProperty('padding-right');
  document.body.style.setProperty('--scrollbar-compensation', `0px`);

  if (top) {
    window.scrollTo(0, top);
  }
};

export const isLocked = () => document.body.classList.contains('no-scroll');

type ScrollLockContextType = {
  locks: Set<string>;
  acquire?(id: string): void; // this should never be undefined but it's a workaround for React Refresh bug with React Context
  release?(id: string): void;
};

type ScrollLockAction = { acquire: string; release?: never } | { acquire?: never; release: string };

export const scrollLockReducer: Reducer<Set<string>, ScrollLockAction> = (
  state,
  { acquire, release },
) => {
  if (acquire && !state.has(acquire)) {
    state.add(acquire);
    return new Set([...state]);
  }

  if (release && state.has(release)) {
    state.delete(release);
    return new Set([...state]);
  }

  return state;
};

export const ScrollLockContext = createContext({} as ScrollLockContextType);

export const useLockBodyScroll = (lock: boolean = false) => {
  const { current: lockId } = useRef(uuid());
  const { acquire, release } = useContext(ScrollLockContext);

  useEffect(() => {
    if (!release || !acquire) {
      return;
    }

    if (lock) {
      acquire(lockId);
    } else {
      release(lockId);
    }
  }, [lock, lockId, acquire, release]);

  useEffectOnce(() => () => release?.(lockId));

  return useMemo(
    () => ({
      acquire: () => acquire?.(lockId),
      release: () => release?.(lockId),
      isLocked,
    }),
    [acquire, release, lockId],
  );
};
