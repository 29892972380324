import client from 'utils/client';

import { resetEntitiesState, upsertEntityToState } from 'store/utils';

const querySingleEntity = async ({ state, action }) => {
  const { query, variables, entityType, key } = action;

  try {
    const { data, errors } = await client.query({
      query,
      variables,
    });

    if (errors?.length) {
      throw errors[0];
    }

    const newEntity = data[entityType][0];

    // TODO(Permissions): what happen you dont have access to an entity?
    if (!newEntity) return state;

    delete data[entityType];

    newEntity.extras = data;

    return upsertEntityToState({
      state,
      entityType,
      newEntity,
      key,
    });
  } catch (error) {
    return resetEntitiesState({
      state,
      entityType,
      error,
    });
  }
};

export default querySingleEntity;
