import axios from 'axios';

import config from 'config';

import { updateMetadata } from 'store/metadata';
import { setCsrfHeaders } from './setCsrfHeaders';

export async function csrfRefresh() {
  try {
    const {
      data: { crumb },
    } = await axios.get(config.envConfig.endpoints.auth.csrf);

    setCsrfHeaders(crumb);
    updateMetadata({ csrf_token: crumb });

    return crumb;
  } catch (_) {
    return false;
  }
}
