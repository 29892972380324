import React, { createContext, useContext, useReducer } from 'react';
import reduce from 'lodash/reduce';

function createStateContext<T>(
  reducer: {
    (state: any, action: any): any;
  },
  initialState: T,
  middleware: {
    ([_, dispatch]: [any, any], ContextState: any): any[];
  },
) {
  const middlewares = (middleware && (Array.isArray(middleware) ? middleware : [middleware])) || [];

  type DispatchFunction = (action: { type: string } & Partial<T>) => void;

  const StateContext = createContext<[T, DispatchFunction]>([initialState, () => null]);
  const StateProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const stateContext = reduce(
      middlewares,
      (agg, mw) => mw(agg) || agg,
      useReducer(reducer, initialState),
    );

    return <StateContext.Provider value={stateContext as any}>{children}</StateContext.Provider>;
  };

  const useStateValue = () => useContext(StateContext);
  return { useStateValue, StateProvider, StateContext };
}

export default createStateContext;
