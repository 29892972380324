import axios from 'axios';
import Config from 'config';

import { readMetadataFromCache } from 'store/metadata';

import { getUtmParams } from 'analytics/utils';

const DEFAULT_EXTRAS = {
  integrations: {
    Salesforce: false,
  },
};

const { events: EVENTS_ENDPOINT } = Config.envConfig.endpoints;

const decorateAnalyticsPayload = (payload = {}) => {
  const metadata = readMetadataFromCache();
  if (!payload.user_id) {
    payload.user_id = metadata?.user_id || metadata?.person?.id;
  }

  if (!!payload.user_id) {
    // Only allow salesforce integration as we rely on the user id for upserting
    DEFAULT_EXTRAS.integrations.Salesforce = true;

    DEFAULT_EXTRAS.integrations.Intercom = {
      user_hash: metadata?.intercom_id,
    };
  }

  const is_authenticated = !!metadata?.is_authenticated && !!metadata?.user_id;

  return { payload, is_authenticated };
};

const analytics = {
  context: null,
  group: async (id, traits = {}, extra = {}) => {
    try {
      await window?.analytics?.group(id, traits, extra);
    } catch (_) {}
  },
  identify: async (metadata, userTraits = {}, extra = {}) => {
    const userId = metadata?.user_id;
    //need to guard because .host may not exist during initial onboarding
    const traits = {
      ...userTraits,
      ...getUtmParams(),
      qatalog_uri: metadata?.host || 'no-host-uri',
    };

    traits.email = metadata.person?.email_address || '';

    if (userId) {
      traits.person_id = metadata?.person?.id;
      traits.signed_up_date = metadata?.person?.signed_up_date;
      traits.signup_track = metadata.signup_track;
      traits.opted_in = metadata.email_opt_in;
      traits.firstName = metadata.person?.first_name;
      traits.lastName = metadata.person?.last_name;
      traits.description = metadata.person?.bio;
      traits.title = metadata.person?.job_title;
      traits.job = {
        department: metadata.person?.department?.name ?? null,
        title: metadata.person?.job_title ?? null,
      };
      traits.admin_role = metadata?.person?.org_role;

      if (metadata.org) {
        traits.group_id = metadata.org.id;
        traits.org_id = metadata.org.id;

        //needed by intercom
        traits.company = {
          id: metadata.org.id,
          name: metadata.org.name,
          email_domain: metadata.org.email_domain,
          billing_plan_name: metadata.org?.billing_plan_name,
        };

        //needed by sdfc
        if (!traits.org) {
          traits.org = {
            created_at: metadata.org.created_at,
            ...traits.company,
          };
        }
      }
    }

    const { payload, is_authenticated } = decorateAnalyticsPayload({
      traits,
      extra: {
        ...DEFAULT_EXTRAS,
        ...extra,
      },
    });

    try {
      // Only identify authenticated users as recommended by Segment. See: https://segment.com/docs/connections/sources/catalog/libraries/website/javascript/#identify
      if (is_authenticated) {
        // Use client analytics in priority but fallback to server in case user uses a blocker
        if (window?.analytics) {
          await window.analytics?.identify(userId, payload.traits, payload.extra);
        } else {
          await axios.post(EVENTS_ENDPOINT, {
            events: [
              {
                extra: payload.extra,
                id: userId,
                method: 'analytics.identify',
                traits: payload.traits,
              },
            ],
          });
        }
      }
    } catch (_) {}
  },
  track: async (event, traits = {}, extra) => {
    const { payload, is_authenticated } = decorateAnalyticsPayload({
      event,
      traits,
      extra: {
        ...DEFAULT_EXTRAS,
        ...extra,
      },
    });

    try {
      if (is_authenticated) {
        await axios.post(EVENTS_ENDPOINT, {
          events: [
            {
              extra: payload.extra,
              id: payload.event,
              method: 'analytics.track',
              traits: payload.traits,
            },
          ],
        });
      } else {
        await window?.analytics?.track(payload.event, payload.traits, payload.extra);
      }
    } catch (_) {}
  },
  page: async (name, traits, extra) => {
    const { payload, is_authenticated } = decorateAnalyticsPayload({
      name,
      traits,
      extra: {
        ...DEFAULT_EXTRAS,
        ...extra,
      },
    });

    try {
      if (is_authenticated) {
        await axios.post(EVENTS_ENDPOINT, {
          events: [
            {
              extra: payload.extra,
              id: payload.name,
              method: 'analytics.page',
              traits: {
                ...payload.traits,
                url: window.location.href,
                path: window.location.pathname + window.location.hash,
              },
            },
          ],
        });
      } else {
        await window?.analytics?.page(
          payload.name,
          {
            ...payload.traits,
            url: window.location.href,
            path: window.location.pathname + window.location.hash,
          },
          payload.extra,
        );
      }
    } catch (_) {}
  },
};

export default analytics;
