import {
  LazyExoticComponent,
  VFC,
  FC,
  createContext,
  useReducer,
  useContext,
  Reducer,
  useCallback,
  HTMLAttributes,
  PropsWithChildren,
} from 'react';

import type { Without } from 'types';
import { useLockBodyScroll } from 'utils/overlays';
import { EntityType } from 'mosaiq/types';

export interface NavSidebarState {
  navSidebar: NavSidebarProps<any> | null;
}

export interface NavSidebarAction {
  type: NavSidebarActionType;
  navSidebar?: NavSidebarProps<any>;
}

export interface NavSidebarActionProps {
  close: () => void;
}

export interface NavSidebarProps<T extends NavSidebarActionProps> {
  Component: VFC<T> | LazyExoticComponent<VFC<T>>;
  props?: Without<T, NavSidebarActionProps>;
  path?: string;
  entity?: EntityType;
  title?: String;
  Icon?: React.ReactNode;
  navSidebarProps?: HTMLAttributes<HTMLDivElement> & {
    width?: string;
  };
  navSidebarOverlayProps?: Omit<HTMLAttributes<HTMLDivElement>, 'onClick'>;
}

export interface NavSidebarContextValue extends NavSidebarState {
  openNavSidebar: <T extends NavSidebarActionProps>(navSidebar: NavSidebarProps<T>) => void;
  closeNavSidebar: () => void;
  navSidebarProps?: HTMLAttributes<HTMLDivElement> & {
    width?: string;
  };
  navSidebarOverlayProps?: Omit<HTMLAttributes<HTMLDivElement>, 'onClick'>;
}

enum NavSidebarActionType {
  OPEN_NAV_SIDEBAR = 'openNavSidebar',
  CLOSE_NAV_SIDEBAR = 'closeNavSidebar',
}

const initialState: NavSidebarState = {
  navSidebar: null,
};

const navSidebarReducer: Reducer<NavSidebarState, NavSidebarAction> = (state, action) => {
  switch (action.type) {
    case NavSidebarActionType.OPEN_NAV_SIDEBAR:
      return {
        ...state,
        navSidebar: action.navSidebar!,
      };

    case NavSidebarActionType.CLOSE_NAV_SIDEBAR:
      return {
        ...state,
        navSidebar: null,
      };

    default:
      return state;
  }
};

export const NavSidebarContext = createContext<NavSidebarContextValue>(
  {} as NavSidebarContextValue,
);

export const useNavSidebar = () => useContext(NavSidebarContext);

export const NavSidebarProvider: FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(navSidebarReducer, initialState);
  useLockBodyScroll(!!state.navSidebar);

  const closeNavSidebar = useCallback(() => {
    dispatch({
      type: NavSidebarActionType.CLOSE_NAV_SIDEBAR,
    });
  }, []);

  const openNavSidebar = useCallback(
    <T extends NavSidebarActionProps>(navSidebar: NavSidebarProps<T>) => {
      dispatch({
        type: NavSidebarActionType.OPEN_NAV_SIDEBAR,
        navSidebar,
      });
    },
    [],
  );

  return (
    <NavSidebarContext.Provider
      value={{
        ...state,
        closeNavSidebar,
        openNavSidebar,
        navSidebarProps: state.navSidebar?.navSidebarProps ?? {},
        navSidebarOverlayProps: state.navSidebar?.navSidebarOverlayProps ?? {},
      }}
    >
      {children}
    </NavSidebarContext.Provider>
  );
};
