import { print } from 'graphql';
import {
  ApolloClient,
  InMemoryCache,
  HttpLink,
  ApolloLink,
  split,
  HttpOptions,
  NormalizedCacheObject,
} from '@apollo/client';
import { offsetLimitPagination } from '@apollo/client/utilities';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { CachePersistor, LocalForageWrapper } from 'apollo3-cache-persist';
import { RestLink } from 'apollo-link-rest';
import localForage from 'localforage';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/browser';
import { SeverityLevel } from '@sentry/browser';
import isEmpty from 'lodash/isEmpty';
import Lockr from 'lockr';
import axios from 'axios';
import { buildAxiosFetch } from '@lifeomic/axios-fetch';

import Config from 'config';
import { readStaticMetadata } from 'store/metadata';

import { showInAppNotificationOnce } from './notifications';
import {
  captureErrorOnSentry,
  handleRequestError,
  RETRY_STATUS_CODES,
  translateErrno,
  onErrorMessage,
} from './errors';

const groupedKeyArgs = ['filters', 'group_by', 'join', 'order_by', 'second_join', 'where'];

export const cache = new InMemoryCache({
  canonizeResults: true,
  typePolicies: {
    EntityInstance: {
      merge: true,
      fields: {
        assignments: offsetLimitPagination(['where', 'filters', 'order_by']),
        workflows: offsetLimitPagination(['where', 'filters', 'order_by']),
      },
    },
    EntityType: {
      merge: true,
    },
    OrgEntity: {
      /**
       * Prevent normalization for OrgEntity as we don't always ask for nested entity ID
       * and store `entity` objects as values instead of references (important for pagination)
       */
      keyFields: false,
      merge: true,
    },
    Page: {
      merge: true,
    },
    QuickView: {
      merge: true,
    },
    Resource: {
      merge: true,
    },
    Task: {
      merge: true,
    },
    Thread: {
      merge: true,
    },
    Workflows: {
      merge: true,
    },
    WorkflowsTeam: {
      merge: true,
    },
    WorkflowsProject: {
      merge: true,
    },
    WorkflowAssignments: {
      merge: true,
    },
    Label: {
      merge: true,
    },
    Team: {
      fields: {
        assignments: offsetLimitPagination(['where', 'filters', 'order_by']),
        workflows: offsetLimitPagination(['where', 'filters', 'order_by']),
      },
    },
    Templates: {
      merge: true,
    },
    Pin: {
      merge: true,
    },
    Traits: {
      merge: true,
    },
    NavigationItem: {
      merge: true,
    },
    View: {
      merge: true,
    },
    People: {
      merge: true,
    },
    Query: {
      fields: {
        all_locations: offsetLimitPagination(['order_by', 'where']),
        assignments: offsetLimitPagination(['filters', 'order_by', 'where']),
        assistant_mappings: offsetLimitPagination(['order_by', 'where']),
        content_templates: offsetLimitPagination(['order_by', 'where']),
        countries: offsetLimitPagination(['where']),
        entity_instances: offsetLimitPagination([
          'filters',
          'join',
          'order_by',
          'subscriber_id',
          'where',
          'second_join',
        ]),
        grouped_entity_instances: offsetLimitPagination([...groupedKeyArgs, 'subscriber_id']),
        grouped_pages: offsetLimitPagination([...groupedKeyArgs, 'subscriber_id']),
        grouped_tasks: offsetLimitPagination([...groupedKeyArgs]),
        grouped_threads: offsetLimitPagination([...groupedKeyArgs, 'subscriber_id']),
        locations: offsetLimitPagination(['join', 'order_by', 'where']),
        my_tasks: offsetLimitPagination(['order_by', 'where']),
        pages: offsetLimitPagination(['filters', 'join', 'order_by', 'where']),
        pages_hierarchy: offsetLimitPagination(['entity_id', 'page_id', 'where']),
        pages_history: offsetLimitPagination(['order_by', 'page_id', 'where']),
        people: offsetLimitPagination(['filters', 'order_by', 'subscriber_id', 'where', 'join']),
        resources: offsetLimitPagination(['join', 'order_by', 'where']),
        search_icons: offsetLimitPagination(['where']),
        tags: offsetLimitPagination(['order_by', 'where']),
        tasks: offsetLimitPagination(['join', 'order_by', 'second_join', 'where']),
        teams: offsetLimitPagination(['filters', 'order_by', 'subscriber_id', 'where']),
        timezones: offsetLimitPagination(['filters', 'order_by', 'where']),
        threads: offsetLimitPagination(['join', 'order_by', 'subscriber_id', 'where']),
        workflow_templates: offsetLimitPagination(['order_by', 'where']),
        workflows: offsetLimitPagination(['filters', 'order_by', 'where', 'join']),
      },
    },
  },
});

const storage = localForage.createInstance({
  name: 'apollo',
  storeName: 'apollo-persist',
  description: 'Persistent storage for apollo cache',
});

const persistor = new CachePersistor({
  cache,
  storage: new LocalForageWrapper(storage),
  debug: process.env.NODE_ENV === 'development',
});

const defaultOptions = {
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
  watchQuery: {
    fetchPolicy: 'no-cache',
    skipPollAttempt: () => document.hidden,
  },
} as const;

const httpLinkConfig: HttpOptions = {
  uri: Config.envConfig.endpoints.graphql,
  credentials: 'include',
  headers: {
    'X-Qatalog-Csrf-Token': readStaticMetadata()?.csrf_token,
  },
};

const defaultHttpLink = new HttpLink(httpLinkConfig);
const batchHttpLink = new BatchHttpLink(httpLinkConfig);

const httpLink = split(({ getContext }) => !getContext().noBatch, batchHttpLink, defaultHttpLink);

export const clientVersionInterceptor = (clientVersion?: string) => {
  if (!clientVersion) return false;

  const currentClientVersion = Lockr.get<string>('client_version');

  if (currentClientVersion !== clientVersion) {
    Lockr.set('client_version', clientVersion);
  }

  if (!!currentClientVersion) {
    const currentMajorClientVersion = Number(currentClientVersion.split('.')[0]);
    const majorClientVersion = Number(clientVersion?.split('.')[0]);

    if (currentMajorClientVersion < majorClientVersion) {
      showInAppNotificationOnce('client_version', {
        content: 'A new version of Qatalog is available 🎉',
        config: {
          position: 'bottom-right',
          autoClose: false,
          hideProgressBar: true,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: false,
        },
        cta: {
          label: 'Refresh',
          onClick: () => window.location.reload(),
        },
      });

      return true;
    } else {
      return false;
    }
  }

  return false;
};

const clientVersionInterceptorLink = new ApolloLink((operation, forward) => {
  return forward(operation).map((response) => {
    const context = operation.getContext();

    const shouldClearCache = clientVersionInterceptor(
      context.response?.headers?.get('x-qatalog-client-version'),
    );

    if (shouldClearCache) {
      persistor.purge();
    }

    return response;
  });
});

const handleError = (error: any) => {
  onErrorMessage(translateErrno(error));
  captureErrorOnSentry(error);
};

// TODO: We need to be returning the error back to the callsites.
// Right now, only networkErrors seems to be thrown at the call site. Not graphqlErrors.
//
// According to https://www.apollographql.com/blog/graphql/error-handling/full-stack-error-handling-with-graphql-apollo/
// the difference between network error and graphql errors seems to be about where in the backend it is thrown.
const graphQLErrorHandlingLink = onError(
  ({ graphQLErrors, networkError, operation: { query, variables, getContext }, forward }) => {
    const operationContext = getContext();

    Sentry.addBreadcrumb({
      category: 'graphql',
      message: `query: ${print(query)}\nvariables: ${JSON.stringify(variables)}`,
      level: 'info' as SeverityLevel,
    });

    if (!isEmpty(networkError)) {
      // @ts-ignore
      const { errno, statusCode, params } = networkError?.result ?? networkError?.cause ?? {};

      // We use `retryLink` already
      if (!RETRY_STATUS_CODES.has(statusCode)) {
        handleRequestError({
          statusCode,
          errno,
          params,
          // @ts-ignore
          handleFn: () => handleError(networkError.result),
        });
      }
    }

    if (!isEmpty(graphQLErrors)) {
      graphQLErrors?.forEach((error) => {
        const { errno, statusCode, params } = error?.extensions ?? {};

        if (operationContext.skipError?.includes?.(errno)) {
          return;
        }

        // @ts-ignore
        handleRequestError({
          statusCode: statusCode as number,
          errno: errno as number,
          params: params as Array<string>,
          handleFn: () => handleError(error.extensions),
        });
      });
    }
  },
);

// This only recieves 4xx and 5xx errors according to apollo docs.
// The normal 4xx errors our backend returns are actually returned
// as 200 in graphql endpoint which means they won't be handled here.
const retryLink = new RetryLink({
  attempts: {
    max: 3,
    retryIf: (error) => !!error && RETRY_STATUS_CODES.has(error?.statusCode),
  },
});

const restLink = new RestLink({
  uri: `/api/`,
  customFetch: buildAxiosFetch(axios),
});

const client = new ApolloClient({
  cache,
  defaultOptions,
  assumeImmutableResults: true,
  link: ApolloLink.from([
    restLink,
    graphQLErrorHandlingLink,
    retryLink,
    clientVersionInterceptorLink,
    httpLink,
  ]),
});

export function initApolloClientRefetch(client: ApolloClient<NormalizedCacheObject>) {
  document.addEventListener('visibilitychange', () => {
    if (!document.hidden) {
      client.refetchQueries({ include: 'active' });
    }
  });
}

export default client;
