import { createGlobalStyle } from 'styled-components/macro';

export const TippyStyles = createGlobalStyle`
  .tippy-box {
    ${({ theme }) => theme.typography.body2()};
    background-color: ${({ theme }) => theme.colors.black};
    display: inline-flex;
    width: max-content;
    border-radius: ${({ theme }) => theme.spacing(1.5)}px;

    &[data-theme="menu"] {
      background-color: transparent;
      color: ${({ theme }) => theme.colors.black};
      margin-bottom: ${({ theme }) => theme.spacing(2)}px;
      ${({ theme }) => theme.typography.body1()};

      .tippy-content {
        padding: 0px;
      }

      .tippy-arrow {
        display: none;
      }
    }
  }
`;
