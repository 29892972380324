import { MouseEvent, RefObject } from 'react';

import { showInAppNotification } from './notifications';
import { URL_REGEX } from './regex';

export const safelyClearLocalStorage = (extraUnsafeKeys?: string[]) => {
  const unsafeKeys = extraUnsafeKeys
    ? [...extraUnsafeKeys.map((key) => 'qatalog_' + key), 'qatalog_postDrafts']
    : ['qatalog_postDrafts'];
  const localKeys = Object.keys(localStorage);

  localKeys?.forEach((item) => {
    if (!unsafeKeys.includes(item)) {
      localStorage.removeItem(item);
    }
  });
};

export const logoutUser = (e?: MouseEvent<HTMLElement>, unsafeKeys?: string[]) => {
  logoutCleanup(unsafeKeys);
  window.location.href = '/logout';
};

function logoutCleanup(unsafeKeys?: string[]) {
  if (localStorage) {
    safelyClearLocalStorage(unsafeKeys);
  }

  if (sessionStorage) {
    sessionStorage.clear();
  }
}

export function logoutUserOnRootDomain(e?: MouseEvent<HTMLElement>, unsafeKeys?: string[]) {
  logoutCleanup(unsafeKeys);
  window.location.href = 'https://qatalog.com/logout';
}

export const getInitials = (name?: string, single = false) => {
  if (!name) return '';

  const [firstName, lastName] = name.split(' ');
  const firstNameLetter = firstName.charAt(0).toUpperCase();
  // For micro avatars and breadcrumbs, we only want the first initial
  if (single) return firstNameLetter;
  // There is no lastname if the name is an username
  const lastNameLetter = lastName ? lastName.charAt(0).toUpperCase() : '';
  return `${firstNameLetter}${lastNameLetter}`;
};

export const focusInput = ({
  inputId,
  focus,
  ref,
  onFocus,
}: {
  inputId?: string;
  focus?: boolean;
  ref?: RefObject<Element>;
  onFocus?: (didFocus?: boolean) => void;
} = {}) => {
  if (!ref || !ref.current) {
    return;
  }

  // escape hatch to directly find the input dom component
  // until react does something better with refs
  const input = ref.current.querySelector(
    inputId ? `#${inputId}` : 'input',
  ) as HTMLInputElement | null;

  if (focus) {
    input && input.focus();
    onFocus?.call(this, true);
  } else {
    input && input.blur();
    onFocus?.call(this, false);
  }

  return input;
};

export const copyToClipboard = (text: string) => {
  if (navigator.clipboard) {
    navigator.clipboard.writeText(text);
  } else {
    const input = document.createElement('input');
    document.body.appendChild(input);
    input.value = text;
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
  }

  showInAppNotification({
    content: 'Copied to clipboard',
  });
};

export const calculatePercentage = ({
  initial,
  target,
  current = initial,
}: {
  initial: number;
  current?: number;
  target: number;
}) => {
  const range = Math.abs(target - initial);
  const progress =
    (target > initial && current > initial) || (target < initial && current < initial)
      ? Math.abs(current - initial)
      : 0;

  return Math.round(Math.max(0, Math.min(progress / range, 1)) * 100);
};

let windowObjectReference: Window | null = null;
export const openPopupWindow = ({
  name,
  url,
  width = 460,
  height = 700,
}: {
  name: string;
  url: string;
  width?: number;
  height?: number;
}) => {
  const popupHeight = window.innerHeight < height ? window.innerHeight : height;
  const popupWidth = window.innerWidth < width ? window.innerWidth : width;
  const top = window.top!.outerHeight / 2 + window.top!.screenY - popupHeight / 2;
  const left = window.top!.outerWidth / 2 + window.top!.screenX - popupWidth / 2;

  // XXX: can't set `noopener, noreferrer` here without breaking redirect behaviour
  windowObjectReference = window.open(
    url,
    name,
    `toolbar=no, menubar=no, width=${popupWidth}, height=${popupHeight}, top=${top}, left=${left}`,
  );

  try {
    windowObjectReference?.focus();
  } catch (e) {
    alert('In order to continue, please configure your browser to allow popups for this site.');
  }

  return windowObjectReference;
};

export const capitalizeFirstLetter = (str?: string) => {
  if (!str) return '';
  return str?.charAt(0)?.toUpperCase() + str?.slice(1);
};

export const fileTypesMap = {
  Document: '.txt,.doc,.docx,.odt',
  HTML: '.html',
  Presentation:
    '.pps,application/vnd.ms-powerpoint,application/vnd.openxmlformats-officedocument.presentationml.slideshow,application/vnd.openxmlformats-officedocument.presentationml.presentation',
  Spreadsheet:
    '.xlsx,.xls,.csv,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel',
  PDF: '.pdf',
  Image: 'image/*',
  Video: 'video/*',
  Audio: 'audio/*',
};

/**
 * https://regex101.com/r/LyF2ot/1
 *
 * This function replaces the urls for html links of a plain text.
 * It will not work if gets a html string because it will convert the
 * <a href..d and the <img src... too.
 *
 * @param {String} text
 */
export const replaceUrlsWithLinks = (text?: string) => {
  if (!text) return '';

  const regex = new RegExp(`\\b${URL_REGEX.source}`, 'gim');
  return text.replace(regex, (url) => `<a href="${url}" target="_blank">${url}</a>`);
};

export const formatCommaSeparatedNumber = <N extends Number>(num?: N) =>
  Number.isFinite(num) ? Number(num).toLocaleString() : null;

export function truncateString(str: string, n: number) {
  if (str.length > n) {
    return str.substring(0, n) + '...';
  } else {
    return str;
  }
}

/**
 * Create an array of consecutive integers
 *
 * @param size elements in array
 * @param start number to start at
 * @returns array of length size
 */
export const range = (size: number = 5, start: number = 0) =>
  new Array(size).fill(null).map((_, i) => i + start);

/**
 * Reorder an array, moving element at position `source` to position `destination`,
 * and shifting other elements where necessary
 *
 * @param arr any arrayƒ
 * @param source index from which to move element
 * @param destination index to which to move element
 * @returns cloned, reordered array
 */
export const reorder = <T>(arr: T[], source: number, destination: number): T[] => {
  const clone = [...arr];
  const [temp] = clone.splice(source, 1);
  clone.splice(destination, 0, temp);
  return clone;
};

/**
 * Inserts an item into the array at the given index,
 * and shifting other elements where necessary
 *
 * @param arr any array
 * @param index target index
 * @param item new array item
 * @returns new array with item inserted
 */
export const insert = <T>(arr: T[], index: number, item: T): T[] => [
  ...arr.slice(0, index),
  item,
  ...arr.slice(index),
];
