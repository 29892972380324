export const personFlags = {
  onboarding: {
    setup: 'setup', // new onboarding flow
    freeTrialDemoVideoWatched: 'free_trial_demo_video_watched',
  },
  search: {
    isAssistedSearchEnabled: 'is_assisted_search_enabled',
  },
  settings: {
    invitesSent: 'invites_sent',
  },
};
