export interface SidebarProps {
  [key: string]: any;
}

export interface SidebarElement {
  close: (e?: React.UIEvent, options?: { force?: boolean }) => void;
  closeAll: () => void;
  navigate?: (index: number, props?: SidebarProps) => void;
}

export type SidebarPaths = Array<{
  content: React.FC<SidebarElement>;
  props?: SidebarProps;
  onOpen?: () => void;
  onClose?: () => void;
  onInterceptClose?: () => boolean;
  paths?: SidebarPaths;
  disableEsc?: boolean;
  [key: string]: any;
}>;

export interface Sidebar {
  content: React.FC<SidebarElement>;
  props?: SidebarProps;
  onOpen?: () => void;
  onClose?: () => void;
  onInterceptClose?: () => boolean;
  paths?: SidebarPaths;
  offsets?: {
    top?: number;
    left?: number;
    bottom?: number;
    right?: number;
  };
  [key: string]: any;
}

export interface SidebarState {
  sidebars: Sidebar[];
  interceptor?: () => boolean;
}

interface SidebarAction {
  type: ActionType;
  sidebar?: Sidebar;
  index?: number;
  id?: string;
  interceptor?: () => boolean;
}

type ActionType =
  | 'sidebar/open'
  | 'sidebar/close'
  | 'sidebar/closeAll'
  | 'sidebar/openPath'
  | 'sidebar/interceptClose'
  | 'sidebar/navigate'
  | 'sidebar/update';

const initialState: SidebarState = {
  sidebars: [],
};

const sidebarReducer = (
  state: SidebarState = initialState,
  action: SidebarAction,
): SidebarState => {
  const reducerMap = {
    'sidebar/open': () => {
      return action.sidebar
        ? {
            sidebars: [...state.sidebars, action.sidebar],
          }
        : state;
    },
    'sidebar/close': () => {
      if (!state.interceptor || state.interceptor?.()) {
        const index = state.sidebars?.findIndex((sidebar) => sidebar.id === action.id);

        const sidebars = [...state.sidebars];

        if (typeof index === 'number' && index >= 0) {
          sidebars.splice(index, 1);
        } else {
          sidebars.pop();
        }

        return {
          ...state,
          sidebars,
        };
      }

      return state;
    },
    'sidebar/closeAll': () => {
      if (!state.interceptor || state.interceptor?.()) {
        return {
          ...state,
          sidebars: [],
        };
      }

      return state;
    },
    'sidebar/openPath': () => {
      const index = action?.index;
      const path = typeof index !== 'undefined' ? action?.sidebar?.paths?.[index] : null;

      return action?.sidebar && path
        ? {
            ...state,
            sidebars: [...state.sidebars, action.sidebar, path],
          }
        : state;
    },
    'sidebar/navigate': () => {
      const index = action?.index;
      const lastSidebar = state.sidebars?.[state.sidebars?.length - 1];
      const paths = lastSidebar?.paths;
      const sidebar = typeof index !== 'undefined' ? paths?.[index] : null;

      return sidebar
        ? {
            ...state,
            sidebars: [...state.sidebars, sidebar],
          }
        : state;
    },
    'sidebar/interceptClose': () => {
      return { ...state, interceptor: action.interceptor };
    },
    'sidebar/update': () => {
      const freshState = { ...state };
      const newSidebar = action?.sidebar;

      const index = freshState.sidebars.findIndex((sb) => sb.id === newSidebar?.id);

      if (index > -1 && newSidebar && freshState?.sidebars?.[index]) {
        freshState.sidebars[index] = newSidebar;
      }

      return {
        ...freshState,
      };
    },
  };

  return reducerMap[action?.type] ? reducerMap[action?.type]() : state;
};

export default sidebarReducer;
