import { gql, useQuery } from '@apollo/client';
import { permissions, names } from '@qatalog/common';
import { EntityType, SingularEntityType } from 'mosaiq/types';

import { Entity, Permission, Person } from 'types';

const { PRECEDENCE } = permissions;

export const OWNER_PERMISSIONS = {
  owner: true,
};

export const ADMIN_PERMISSIONS = {
  ...OWNER_PERMISSIONS,
  admin: true,
};

export const MEMBER_PERMISSIONS = {
  ...ADMIN_PERMISSIONS,
  member: true,
};

export const VIEWER_PERMISSIONS = {
  ...MEMBER_PERMISSIONS,
  viewer: true,
};

export const getEntityPermissions = ({
  accessEntityId,
  entity,
}: {
  accessEntityId: string;
  entity?: Entity | { entity?: Entity };
}) => {
  const permissions =
    (entity as Entity)?.permissions ?? (entity as { entity?: Entity })?.entity?.permissions;
  return permissions
    ? permissions.reduce<{ members: Person[]; permissions: Permission | {} }>(
        (result, p) => {
          if (p.access_entity_id === accessEntityId) {
            result.permissions = p;
          }

          if (
            MEMBER_PERMISSIONS[p.role as keyof typeof MEMBER_PERMISSIONS] &&
            !!p.access_entity?.person
          ) {
            result.members.push(p.access_entity.person);
          }

          return result;
        },
        { members: [], permissions: {} },
      )
    : {};
};

export const getEntityMembers = (entity: Entity, filteredIds: string[] = []) =>
  entity.permissions
    ?.map((p) => p.access_entity?.person)
    .filter((member) => !!member && !member?.entity?.is_archived)
    .filter((member) => !filteredIds.includes(member.id));

export const buildPermissions = (role = 'none', entityId?: string, currentUserId?: string) => {
  const isSelf = !!entityId && !!currentUserId && entityId === currentUserId;

  return {
    isOwner: isSelf || (PRECEDENCE[role] ?? -1) >= PRECEDENCE['owner'],
    isAdmin: isSelf || (PRECEDENCE[role] ?? -1) >= PRECEDENCE['admin'],
    isMember: isSelf || (PRECEDENCE[role] ?? -1) >= PRECEDENCE['member'],
    isViewer: isSelf || (PRECEDENCE[role] ?? -1) >= PRECEDENCE['viewer'],
  };
};

const GET_PERMISSIONS = gql`
  query EntityPermissions($where: JSON) {
    entities(where: $where) {
      visibility
      actions
      permissions {
        access_entity_id
        private_entity_id
        role
      }
      entity_instance {
        display_name
      }
      person {
        display_name
      }
      task {
        name
      }
      workflow {
        name
      }
      team {
        name
      }
    }
  }
`;

export const useAllEntityPermissions = (entityId?: string, owningEntityType?: string) => {
  const { data, loading } = useQuery<{ entities: Partial<Entity>[] }>(GET_PERMISSIONS, {
    skip: !entityId,
    variables: { where: { id: { _eq: entityId } } },
  });

  const canViewEntity = (accessEntityId?: string) => {
    if (!data) return false;
    if (data?.entities[0]?.visibility === 'everyone') return true;

    return !!data?.entities[0]?.permissions?.find((p) => p.access_entity_id === accessEntityId);
  };

  function getDisplayName(obj: any, key: string) {
    if (obj && key) {
      switch (key) {
        case EntityType.TEAMS:
        case EntityType.TASKS:
        case EntityType.WORKFLOWS:
          return obj[names.singular(key)].name;

        default:
          return (
            obj[names.singular(key)]?.display_name ?? obj[SingularEntityType.CUSTOM]?.display_name
          );
      }
    }
  }

  const displayName = getDisplayName(data?.entities[0], owningEntityType ?? '');
  const displayType =
    !!owningEntityType && owningEntityType in EntityType
      ? names.singular(names.displayName(owningEntityType))
      : 'item';

  return {
    displayName: displayName,
    displayType: displayType,
    actions: data?.entities[0]?.actions,
    permissions: data?.entities[0]?.permissions,
    loading,
    canViewEntity,
  };
};
