import { css } from 'styled-components/macro';
import { mosaiqTheme } from '@qatalog/mosaiq-ui';
import { boxShadow, dropShadow } from './shadows';

import {
  EmojiMartStyles,
  MediumZoomedImage,
  JoyrideStyles,
  ReactRndStyles,
  TippyStyles,
  ToastifyStyles,
  CustomShadows,
} from './libs';

export const ExternalLibStyles = () => (
  <>
    <EmojiMartStyles />
    <MediumZoomedImage />
    <TippyStyles />
    <ToastifyStyles />
    <JoyrideStyles />
    <ReactRndStyles />
    <CustomShadows />
  </>
);

const theme = {
  statusColors: {
    // TODO: Update with active/hover states for status colors
    setup: {
      default: mosaiqTheme.colors.body,
      hover: mosaiqTheme.colors.body,
      active: mosaiqTheme.colors.body,
      background: mosaiqTheme.colors.grey40,
    },
    completed: {
      default: mosaiqTheme.colors.action,
      hover: mosaiqTheme.colors.action,
      active: mosaiqTheme.colors.action,
      background: mosaiqTheme.colors.portage,
    },
    on_hold: {
      default: mosaiqTheme.colors.warning,
      hover: mosaiqTheme.colors.warning,
      active: mosaiqTheme.colors.warning,
      background: mosaiqTheme.colors.navajoWhite,
    },
    at_risk: {
      default: mosaiqTheme.colors.error,
      hover: mosaiqTheme.colors.error,
      active: mosaiqTheme.colors.error,
      background: mosaiqTheme.colors.apricotPeach,
    },
    on_track: {
      default: mosaiqTheme.colors.emerald,
      hover: mosaiqTheme.colors.emerald,
      active: mosaiqTheme.colors.emerald,
      background: mosaiqTheme.colors.mould,
    },
    in_progress: {
      default: mosaiqTheme.colors.emerald,
      hover: mosaiqTheme.colors.emerald,
      active: mosaiqTheme.colors.emerald,
      background: mosaiqTheme.colors.mould,
    },
    open: {
      default: mosaiqTheme.colors.black,
      hover: mosaiqTheme.colors.black,
      active: mosaiqTheme.colors.black,
      background: mosaiqTheme.colors.grey40,
    },
  },
  colorPairs: [
    [mosaiqTheme.colors.coral, mosaiqTheme.colors.apricotPeach],
    [mosaiqTheme.colors.plum, mosaiqTheme.colors.maverick],
    [mosaiqTheme.colors.sandyBrown, mosaiqTheme.colors.navajoWhite],
    [mosaiqTheme.colors.lavender, mosaiqTheme.colors.melrose],
    [mosaiqTheme.colors.persianBlue, mosaiqTheme.colors.portage],
    [mosaiqTheme.colors.iceCold, mosaiqTheme.colors.whiteIce],
  ],
  boxShadow,
  dropShadow,
  headerHeight: 80,
  entityViewPanelWidth: 376,
  appHeaderHeight: 77,
  dashboardSearchFiltersDrawerSize: 358,
  defaultHeaderHeight: 104,
  drawerWidth: 300,
  drawerWidthCollapsed: 80,
  viewMaxWidth: 1600,
  appWidth: 2560,
  contentWidth: 2480,
  focusedContentWidth: 960,
  postsArticleTypography: () => `
    font-weight: normal;
    font-size: 18px;
    line-height: 32px;
  `,
  underlinedLinkMixin: (theme: typeof mosaiqTheme) => css`
    a {
      cursor: pointer;
      text-underline-offset: ${theme.spacing(0.5)}px;
      text-decoration: underline dotted #535353;

      &:hover {
        text-decoration-style: solid;
      }
    }
  `,
};

export default theme;
