import React, { useContext, useEffect, useState, createContext } from 'react';
import Cookies from 'js-cookie';

const findUtmParams = () => {
  const utmParams = {};
  const params = new URLSearchParams(window.location.search);
  params.forEach((val, param) => {
    if (param.startsWith('utm_')) {
      utmParams[param] = val;
    }
  });
  return utmParams;
};

export const MarketingDataContext = createContext();
export const MarketingDataConsumer = MarketingDataContext.Consumer;

export const MarketingDataProvider = ({ children }) => {
  const [utmParams, setUtmParams] = useState([]);
  useEffect(() => {
    const storedUTMs = Cookies.get('__utms__');
    const utmParams = storedUTMs ? JSON.parse(storedUTMs) : findUtmParams();
    if (utmParams) {
      setUtmParams(utmParams);
    }
  }, []);

  return (
    <MarketingDataContext.Provider value={[utmParams]}>{children}</MarketingDataContext.Provider>
  );
};

export const useMarketingDataContext = () => useContext(MarketingDataContext);
