import { personalEmailDomains } from '@qatalog/common';

import { EMAIL_REGEX } from 'utils/regex';

export const validateEmail = (email: string) => {
  return EMAIL_REGEX.test(email);
};

const PERSONAL_EMAIL_DOMAINS = new Set(personalEmailDomains);

export function isPersonalEmail(email: string) {
  const emailDomain = email.split('@')[1]?.toLowerCase();
  return PERSONAL_EMAIL_DOMAINS.has(emailDomain);
}

export function isValidHttpUrl(str: string) {
  let url;

  try {
    url = new URL(str);
  } catch (_) {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
}

export const prefixUrlIfNeeded = (url: string) => {
  if (isValidHttpUrl(url)) return url;
  try {
    const prefixed = new URL(`https://${url}`);
    return prefixed.href;
  } catch (e) {
    return '';
  }
};
