import axios from 'axios';

import Config from 'config';
import SessionLockr from 'utils/session-storage';

import { Person } from 'types';
import { LoginState, Session, StaticSessionData } from './types';

const METADATA_KEY = 'METADATA_KEY';

export const readMetadataFromCache = <L extends LoginState = 'authed'>():
  | Session<L>
  | null
  | undefined => SessionLockr.get<Session<L>>(METADATA_KEY);

export const cacheMetadata = (data: Session | {}) => SessionLockr.set(METADATA_KEY, data);

export const uncacheMetadata = () => SessionLockr.rm(METADATA_KEY);

const getMetadata = async <L extends LoginState = 'authed'>() => {
  try {
    const { data } = await axios.get<Session<L>>(Config.envConfig.endpoints.sessions.the);
    return data;
  } catch (_) {}
};

export const readMetadata = async <L extends LoginState = 'authed'>(
  shouldUpdate: (metadata?: Session<L>) => boolean = () => true,
) => {
  try {
    let metadata = readMetadataFromCache<L>();

    if (!metadata || shouldUpdate(metadata)) {
      metadata = await fetchMetadata<L>();
    }

    return metadata;
  } catch (_) {}
};

export const fetchMetadata = async <L extends LoginState = 'authed'>(): Promise<
  Session<L> & StaticSessionData
> => {
  try {
    const data = {
      ...(await getMetadata<L>()),
      ...readStaticMetadata(),
    };

    cacheMetadata(data);

    return data as Session<L> & StaticSessionData;
  } catch (_) {
    return {} as Session<L>;
  }
};

export function readStaticMetadata(): Partial<StaticSessionData> {
  try {
    return JSON.parse(
      decodeURIComponent(
        document.querySelector('meta[name="qatalog"]')?.getAttribute('content') ?? '{}',
      ),
    );
  } catch (_) {
    return {};
  }
}

export const updateMetadata = async <L extends LoginState = 'authed'>(
  session: Partial<Session>,
): Promise<Session<L> & StaticSessionData> => {
  try {
    const data = { ...readMetadataFromCache(), ...session };

    uncacheMetadata();
    cacheMetadata(data);

    return data as Session<L> & StaticSessionData;
  } catch (_) {
    return {} as Session<L>;
  }
};

export const updatePersonFlag = async (key: string, value: unknown) => {
  return axios.put<Person>(Config.envConfig.endpoints.people.flags, {
    flags: { [key]: value },
  });
};
