import React, {
  createContext,
  useReducer,
  useContext,
  Reducer,
  useCallback,
  SyntheticEvent,
  PropsWithChildren,
} from 'react';
import { v4 as uuid } from 'uuid';

import type {
  Modal,
  ModalActionProps,
  ModalsState,
  ModalContextValue,
  Interceptor,
  ModalProps,
} from './types';

enum ModalsActionType {
  OPEN_MODAL = 'openModal',
  CLOSE_MODAL = 'closeModal',
  CLOSE_ALL = 'closeAllModals',
  INTERCEPT_CLOSE = 'interceptClose',
}

type ModalsAction = {
  type: ModalsActionType;
} & (
  | { modal?: Modal<any>; id?: never; interceptor?: never; force?: never }
  | { id?: string; modal?: never; interceptor?: never; force?: boolean }
  | { id?: string; modal?: never; interceptor?: Interceptor; force?: never }
);

const initialState: ModalsState = {
  modals: [],
};

const modalsReducer: Reducer<ModalsState, ModalsAction> = (state, action) => {
  const interceptors = { ...state.interceptors };

  switch (action.type) {
    case ModalsActionType.OPEN_MODAL:
      return action.modal
        ? {
            ...state,
            modals: [...state.modals, action.modal],
          }
        : state;

    case ModalsActionType.CLOSE_MODAL:
      if (action.force) {
        if (!!action.id) {
          delete interceptors?.[action.id];

          return {
            ...state,
            modals: state.modals.filter((modal) => modal?.id !== action.id),
            interceptors,
          };
        }

        return {
          ...state,
          modals: state.modals.splice(0, state.modals.length - 1),
          interceptors,
        };
      } else {
        if (action.id && state.interceptors?.[action.id]?.()) {
          return state;
        } else {
          if (!!action.id!) {
            delete interceptors?.[action.id];
            return {
              ...state,
              modals: state.modals.filter((modal) => modal?.id !== action.id),
              interceptors,
            };
          }

          return {
            ...state,
            modals: state.modals.splice(0, state.modals.length - 1),
            interceptors,
          };
        }
      }

    case ModalsActionType.CLOSE_ALL:
      return {
        ...state,
        interceptors: {},
        modals: [],
      };

    case ModalsActionType.INTERCEPT_CLOSE:
      if (!action.interceptor) {
        delete interceptors?.[action.id!];

        return { ...state, interceptors };
      }

      if (action.id) {
        return {
          ...state,
          interceptors: { ...state.interceptors, [action.id]: action.interceptor },
        };
      }

      return state;
    default:
      return state;
  }
};

export const ModalsContext = createContext<ModalContextValue>({} as ModalContextValue);

export const useModals = () => useContext(ModalsContext);

export const ModalsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [state, dispatch] = useReducer(modalsReducer, initialState);

  const closeAllModals = useCallback(() => {
    dispatch({ type: ModalsActionType.CLOSE_ALL });
  }, []);

  const closeModal = useCallback(
    (id?: string, options?: { force?: boolean } | SyntheticEvent) => {
      const modalId = id ?? state.modals?.[state.modals?.length - 1]?.id;

      dispatch({
        type: ModalsActionType.CLOSE_MODAL,
        id: modalId,
        force: (options as { force?: boolean })?.force,
      });
    },
    [state.modals],
  );

  const openModal = useCallback(<T extends ModalActionProps>(modal: ModalProps<T>) => {
    const id = modal.id ?? uuid();

    dispatch({
      type: ModalsActionType.OPEN_MODAL,
      modal: {
        ...modal,
        id,
        finalFocus: modal.finalFocus ?? (document.activeElement as HTMLElement),
      },
    });

    return id;
  }, []);

  const interceptor = useCallback(
    (id?: string, fn?: Interceptor) =>
      dispatch({ type: ModalsActionType.INTERCEPT_CLOSE, interceptor: fn, id }),
    [],
  );

  const clearIntercept = useCallback(
    () => dispatch({ type: ModalsActionType.INTERCEPT_CLOSE, interceptor: null }),
    [],
  );

  return (
    <ModalsContext.Provider
      value={{
        ...state,
        closeAllModals,
        closeModal,
        openModal,
        interceptor,
        clearIntercept,
      }}
    >
      {children}
    </ModalsContext.Provider>
  );
};
