import React, { MutableRefObject, Suspense, useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { useHotkeys } from 'react-hotkeys-hook';

import { Sidebar, SidebarProps, SidebarState } from '../sidebarReducer';

import ErrorBoundary from 'components/ErrorBoundary';
import { useLockBodyScroll } from 'utils/overlays';

import { SidebarWrapper, SidebarBox, SidebarOverlay } from './SidebarContainer.styles';

interface AppState {
  sidebar?: SidebarState;
}

interface SidebarInstanceProps {
  sidebar: Sidebar;
  closeSidebar: (sidebar?: Sidebar) => void;
  closeAllSidebars: () => void;
  interceptor?: () => boolean;
  index: number;
  activeIndex: boolean;
}

const selectSidebars = createSelector(
  (state: AppState) => state.sidebar,
  (sidebar) => sidebar,
);

const SidebarContainer = (): React.ReactNode => {
  const state = useSelector(selectSidebars);

  useLockBodyScroll(!!state?.sidebars?.length);

  const dispatch = useDispatch();

  const { sidebars, interceptor } = state ?? {};

  const closeSidebar = (sidebar?: Sidebar) => {
    dispatch({
      type: 'sidebar/close',
      id: sidebar?.id,
    });
  };

  const closeAllSidebars = () => {
    dispatch({
      type: 'sidebar/closeAll',
    });
  };

  if (sidebars?.length === 0) {
    return null;
  }

  return sidebars?.map((sidebar: Sidebar, i: number) => (
    <SidebarInstance
      index={i}
      activeIndex={i === sidebars.length - 1}
      sidebar={sidebar}
      closeSidebar={closeSidebar}
      closeAllSidebars={closeAllSidebars}
      interceptor={interceptor}
      key={sidebar?.id}
    />
  ));
};

interface CloseRequest {
  force?: boolean;
}

const SidebarInstance: React.FC<SidebarInstanceProps> = ({
  index,
  sidebar,
  closeSidebar,
  closeAllSidebars,
  interceptor,
}) => {
  const {
    content: SidebarContent,
    props,
    onClose,
    direction = 'rtl',
    width,
    offsets,
    disableEsc,
  } = sidebar;

  const overlayRef = useRef() as MutableRefObject<HTMLDivElement>;

  const [active, setActive] = useState(false);

  const [childRoute, setChildRoute] = useState<{ index: number; props?: SidebarProps } | undefined>(
    undefined,
  );

  const close = (_e?: React.UIEvent, { force = false }: CloseRequest = {}) => {
    if (force || !interceptor || interceptor?.()) {
      setActive(false);
      setTimeout(() => {
        onClose?.();
        closeSidebar(sidebar);
      }, 200);
    }
  };

  const closeAll = (_e?: React.UIEvent, { force = false }: CloseRequest = {}) => {
    if (force || !interceptor || interceptor?.()) {
      setActive(false);
      setTimeout(() => {
        onClose?.();
        closeAllSidebars();
      }, 200);
    }
  };

  const handleOutsideClick = (e: React.MouseEvent) => {
    if (e?.target === overlayRef.current) {
      close();
    }
  };

  useHotkeys(
    'escape',
    () => {
      if (disableEsc) return;
      close();
    },
    [disableEsc, close],
  );

  useEffect(() => {
    setTimeout(() => {
      setActive(true);
    }, 0);
  }, []);

  const navigate = (index: number, props?: SidebarProps) => {
    setChildRoute({ index, props });
  };

  const childSidebarData =
    typeof childRoute?.index === 'number' && sidebar?.paths?.[childRoute.index];

  return (
    <Suspense fallback={null}>
      <div data-testid={`sidebar-${sidebar.id}`}>
        <SidebarOverlay
          ref={overlayRef}
          onClick={handleOutsideClick}
          data-testid={'sidebar-Overlay'}
          $active={index <= 0 && active}
          $direction={direction}
          $offsets={offsets}
        >
          <SidebarWrapper
            $active={active}
            $activeTranslate={index <= 0 ? `translate3d(0, 0, 0)` : `translate3d(10px, 0, 0)`}
            $width={width || 480}
            $direction={direction}
          >
            <SidebarBox $index={index} $activeIndex={typeof childRoute === 'undefined'}>
              <ErrorBoundary>
                <SidebarContent {...props} close={close} closeAll={closeAll} navigate={navigate} />
              </ErrorBoundary>
            </SidebarBox>
          </SidebarWrapper>

          {childSidebarData && (
            <SidebarInstance
              index={index + 1}
              activeIndex={true}
              sidebar={{
                ...childSidebarData,
                props: {
                  ...childSidebarData.props,
                  ...childRoute?.props,
                },
              }}
              interceptor={interceptor}
              closeSidebar={() => setChildRoute(undefined)}
              closeAllSidebars={closeAllSidebars}
            />
          )}
        </SidebarOverlay>
      </div>
    </Suspense>
  );
};

export default SidebarContainer;
