/* eslint-disable qatalog/datetime-helpers */
import {
  addMinutes,
  differenceInDays,
  endOfQuarter,
  format,
  formatDistance,
  formatDistanceStrict,
  formatISO,
  intervalToDuration,
  isSameMinute,
  isSameMonth,
  isSameQuarter,
  isSameYear,
  startOfQuarter,
  addDays,
  startOfMonth,
  setDay,
  getDay,
  isSameDay,
  addWeeks,
  isPast,
  isFuture,
} from 'date-fns';

export type DateInput = string | number | Date;

export type DayIndex = 1 | 2 | 3 | 4 | 5 | 6 | 7;

export enum Daytime {
  MORNING = 'morning',
  AFTERNOON = 'afernoon',
  EVENING = 'evening',
  NIGHT = 'night',
}

export enum Weekday {
  SUNDAY = 'Sunday',
  MONDAY = 'Monday',
  TUESDAY = 'Tuesday',
  WEDNEDSAY = 'Wednesday',
  THURSDAY = 'Thursday',
  FRIDAY = 'Fiday',
  SATURDAY = 'Saturday',
}

export enum Month {
  JANUARY = 'January',
  FEBRUARY = 'February',
  MARCH = 'March',
  APRIL = 'April',
  MAY = 'May',
  JUNE = 'June',
  JULY = 'July',
  AUGUST = 'August',
  SEPTEMBER = 'September',
  OCTOBER = 'October',
  NOVEMBER = 'November',
  DECEMBER = 'December',
}

export enum Quarter {
  Q1 = 'Q1',
  Q2 = 'Q2',
  Q3 = 'Q3',
  Q4 = 'Q4',
}

export const SECOND = 1000;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;

export const TIME = 'h:mm'; // "4:19"
export const TIME_AND_MERIDIEM = `${TIME} aaa`; // "4:19 pm"
export const TIME_AND_MERIDIEM_UCASE = `${TIME} a`; // "4:19 PM"
export const DAY = 'EEE'; // "Fri"
export const DATE = 'd'; // "9"
export const MONTH = 'MMM'; // "Jul"
export const FULL_MONTH = 'MMMM'; // "July"
export const YEAR = 'yyyy'; // "2021"
export const MONTH_AND_DATE = `${MONTH} ${DATE}`; // "Jul 9"
export const MONTH_AND_YEAR = `${MONTH} ${YEAR}`; // "Jul 2021"
export const MONTH_DAY_YEAR = `${MONTH_AND_DATE}, ${YEAR}`; // "Jul 9, 2021"
export const EXACT_DATE = `${TIME_AND_MERIDIEM} • ${MONTH_DAY_YEAR}`; // "4:19 pm • Jul 9, 2021"
export const DAY_AND_DATE = `${DAY} ${MONTH_AND_DATE}`; // "Fri Jul 9"
export const DAY_DATE_YEAR = `${DAY_AND_DATE}, ${YEAR}`; // "Fri Jul 9, 2021"
export const DAY_DATE_TIME = `${DAY_AND_DATE}, ${TIME_AND_MERIDIEM}`; // "Fri Jul 9, 4:19 pm"
export const CALENDAR_DAY = 'EEEE'; // "Friday"
export const CALENDAR_MONTH = 'MMMM'; // "July"
export const CALENDAR_DAY_DATE = `${CALENDAR_DAY}, ${CALENDAR_MONTH} ${DATE}`; // "Friday, July 9"
export const CALENDAR_DAY_DATE_YEAR = `${CALENDAR_DAY} ${CALENDAR_MONTH} ${DATE}, ${YEAR}`; // "Friday July 9, 2021"
export const CALENDAR_MONTH_YEAR = `${CALENDAR_MONTH} ${YEAR}`; // "July 2021"
export const DATE_STAMP = 'yyyy-MM-dd'; // "2021-07-09"
export const QUARTER = 'qqq'; // "Q3"
export const QUARTER_AND_YEAR = `${QUARTER} ${YEAR}`; // "Q3 2021"
export const DAY_OF_MONTH_ORDINAL = 'do'; //1st
export const CLOCK_TIME = 'HH:mm';

const DATESTAMP_PATTERN = /^(?:19|20)\d{2}-\d{2}-\d{2}$/;

const TIME_UNIT_SUBSTITUTIONS: [RegExp, string][] = [
  [/seconds?/, 'sec'],
  [/minutes?/, 'min'],
  [/hours?/, 'hr'],
  [/weeks?/, 'wk'],
  [/months?/, 'mo'],
  [/years?/, 'yr'],
];

export const getDayTime = () => {
  const hour = new Date().getHours();

  if (hour > 4 && hour <= 12) {
    return Daytime.MORNING;
  }

  if (hour > 12 && hour <= 17) {
    return Daytime.AFTERNOON;
  }

  if (hour > 17 && hour <= 21) {
    return Daytime.EVENING;
  }

  return Daytime.NIGHT;
};

export const addDaysToDate = (input?: DateInput | null, days: number = 0) => {
  if (!input) return;

  const date = ensureDate(input);

  return addDays(date, days);
};

const substituteTimeUnits = (input: string): string => {
  return TIME_UNIT_SUBSTITUTIONS.reduce(
    (output, [pattern, substitution]) => output.replace(pattern, substitution),
    input,
  );
};

export const formatAddDayRelative = (input?: DateInput | null) => {
  if (!input) return;

  const date = input instanceof Date ? input : new Date(input);

  return differenceInDays(new Date(), date) <= 0 ? formatTime(date) : formatDayDateTime(date);
};

export const formatShortDate = (input?: DateInput | null) => {
  if (!input) return;

  const date = input instanceof Date ? input : new Date(input);
  return format(date, MONTH_AND_DATE);
};

export const formatFullDate = (input?: DateInput | null) => {
  if (!input) return;

  const date = input instanceof Date ? input : new Date(input);
  return format(date, MONTH_DAY_YEAR);
};

export const formatDate = (input?: DateInput | null) => {
  if (!input) return;

  const date = input instanceof Date ? input : new Date(input);
  if (isSameYear(date, new Date())) {
    return formatShortDate(date);
  }

  return formatFullDate(date);
};

/**
 * Returns an appropriate string representation of a date, depending on how far
 * away it is from now.
 *
 * @param input an ISO or UTC String, timestamp in milliseconds, or Date object
 * @returns a relative datestring if within 1 week (e.g. "about 5 hours ago"),
 * else formatted per the spec (e.g. "Jan 23, 2021")
 * @see https://zeroheight.com/499fccd99/p/98065c-ux-copy-guide/b/55656f
 */
export const formatFriendlyDate = (input?: DateInput | null, suffix: boolean = true) => {
  if (!input) return null;

  const date = input instanceof Date ? input : new Date(input);
  const now = new Date();

  if (isSameMinute(date, now)) return 'just now';
  if (!isSameYear(date, now)) return format(date, MONTH_DAY_YEAR);

  return Math.abs(differenceInDays(now, date)) >= 7
    ? format(date, MONTH_AND_DATE)
    : substituteTimeUnits(formatDistanceStrict(date, now, { addSuffix: suffix }));
};

export const formatMonth = (input?: DateInput | null) => {
  if (!input) return null;

  const date = input instanceof Date ? input : new Date(input);
  return format(date, FULL_MONTH);
};

export const formatFriendlyDistance = (
  input?: DateInput | null,
  options?: {
    addSuffix?: boolean;
    unit?: 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year';
    roundingMethod?: 'floor' | 'ceil' | 'round';
    locale?: Locale;
  },
) => {
  if (!input) return;

  const date = input instanceof Date ? input : new Date(input);
  return substituteTimeUnits(formatDistanceStrict(date, new Date(), options));
};

export const formatRelativeDate = (input?: DateInput | null, options = { addSuffix: true }) => {
  if (!input) return;
  const date = input instanceof Date ? input : new Date(input);
  return substituteTimeUnits(formatDistance(date, new Date(), options));
};

export const formatExactDate = (input?: DateInput | null) => {
  if (!input) return;
  const date = input instanceof Date ? input : new Date(input);
  return format(date, EXACT_DATE);
};

export const formatDateStamp = (input?: DateInput | null) => {
  if (!input) return;
  const date = input instanceof Date ? input : new Date(input);
  return formatISO(date, { representation: 'date' });
};

export const formatTime = (input?: DateInput | null) => {
  const date = ensureDate(input);
  return format(date, TIME_AND_MERIDIEM);
};

export const formatShortTime = (input?: DateInput | null) => {
  const date = ensureDate(input);
  return format(date, TIME);
};

export const formatDayDateTime = (input?: DateInput | null) => {
  return format(new Date(input ?? Date.now()), DAY_DATE_TIME);
};

export const formatTimeRange = (start?: DateInput | null, end?: DateInput | null) => {
  if (!start) return;

  const startTime = start instanceof Date ? start : new Date(start);

  if (!!end) {
    const endTime = new Date(end);
    const spansMeridiem = doesSpanMeridiem(startTime, endTime);
    return `${format(startTime, spansMeridiem ? TIME_AND_MERIDIEM : TIME)}–${formatTime(endTime)}`;
  } else {
    return formatTime(startTime);
  }
};

export const formatCalendarDate = (input?: DateInput | null) => {
  if (!input) return;
  const date = input instanceof Date ? input : new Date(input);
  return format(date, CALENDAR_DAY_DATE);
};

export const formatShortCalendarDate = (input?: DateInput | null) => {
  if (!input) return;
  const date = input instanceof Date ? input : new Date(input);
  return format(date, DAY_AND_DATE);
};

export const daysBetweenTwoDates = (start: DateInput, end: DateInput) => {
  return intervalToDuration({
    start: ensureDate(start),
    end: ensureDate(end),
  }).days;
};

export const formatCalendarDateRelative = (selectedDate?: Date, calendarDate?: Date) => {
  if (!selectedDate || !calendarDate) return;

  const now = new Date();

  if (isSameMonth(selectedDate, calendarDate)) {
    return format(
      selectedDate,
      isSameYear(selectedDate, now) ? CALENDAR_DAY_DATE : CALENDAR_DAY_DATE_YEAR,
    );
  }

  return format(
    calendarDate,
    isSameYear(selectedDate, calendarDate) ? CALENDAR_MONTH : CALENDAR_MONTH_YEAR,
  );
};

const doesSpanMeridiem = (start: Date, end: Date) => {
  return (
    (start.getHours() < 12 && end.getHours() >= 12) ||
    (start.getHours() >= 12 && end.getHours() < 12)
  );
};

/**
 * Returns a properly-localized Date object for a given datestamp
 * string, circumventing issues where calendar dates are incorrectly
 * coerced when they break across days based on timezone and DST.
 *
 * @param datestamp is a string formatted "2021-07-29"
 * @returns a Date at 12:00am local time for input datestamp
 */
export const datestampToLocalDate = (datestamp?: string | null): Date | undefined => {
  if (!datestamp) return;
  const unadjustedDate = new Date(datestamp);
  return addMinutes(unadjustedDate, unadjustedDate.getTimezoneOffset());
};

/**
 * Returns a guaranteed Date, falling back to the default constructor
 * without throwing if the input is malformed. Useful for when formats
 * are unknown, or as an adapter between components dealing with different
 * date structures.
 *
 * @param input an ISO or UTC String, datestamp, timestamp in milliseconds, or Date object
 * @returns a Date, properly localized if locale is amiguous
 */
export const ensureDate = (input?: DateInput | null): Date => {
  if (!input) return new Date();
  if (input instanceof Date) return input;

  if (typeof input === 'string' && DATESTAMP_PATTERN.test(input))
    return datestampToLocalDate(input) as Date;

  try {
    return new Date(input);
  } catch (e) {
    return new Date();
  }
};

export const formatQuartersRange = (start?: DateInput | null, end?: DateInput | null) => {
  if (!start && !end) return;

  const startDate = ensureDate(start);
  const endDate = ensureDate(end);

  if (!!end && !!start) {
    if (!isSameQuarter(startDate, endDate)) {
      return `${format(
        startDate,
        isSameYear(startDate, endDate) ? QUARTER : QUARTER_AND_YEAR,
      )}–${format(endDate, QUARTER_AND_YEAR)}`;
    }
  }

  return format(start ? startDate : endDate, QUARTER_AND_YEAR);
};

export const getQuarterAndYear = (input: DateInput) => {
  const date = ensureDate(input);
  return format(date, `${QUARTER} yy`);
};

export const getQuarterRangeFromDate = (input?: DateInput | null) => {
  const date = ensureDate(input);
  return {
    from: startOfQuarter(date),
    to: endOfQuarter(date),
  };
};

export const formatBillingExpiryDistance = (datestamp: string): string => {
  const now = new Date();
  // NOTE: we use GMT, not local datetime, to determine instant of exipry.
  // The instant a trial expires is 12AM GMT on the day AFTER the exipry date.
  const expiry = addDays(new Date(datestamp), 1);
  return formatDistanceStrict(now, expiry);
};

export const formatDayOfTheWeek = (input?: DateInput): string => {
  const date = ensureDate(input);
  return format(date, CALENDAR_DAY);
};

export const formatDayOfTheMonth = (input?: DateInput): string => {
  const date = ensureDate(input);
  return format(date, DAY_OF_MONTH_ORDINAL);
};

export const formatNthWeekOfDayInMonth = (dayIndex: DayIndex, input?: DateInput) => {
  const date = ensureDate(input);
  const startOfCurrentMonth = startOfMonth(date);
  const firstWeekOfDay = setDay(startOfCurrentMonth, dayIndex, {
    weekStartsOn: getDay(startOfCurrentMonth),
  });

  if (isSameDay(firstWeekOfDay, date)) {
    return 1;
  } else if (isSameDay(addWeeks(firstWeekOfDay, 1), date)) {
    return 2;
  } else if (isSameDay(addWeeks(firstWeekOfDay, 2), date)) {
    return 3;
  } else if (isSameDay(addWeeks(firstWeekOfDay, 3), date)) {
    return 4;
  } else if (isSameDay(addWeeks(firstWeekOfDay, 4), date)) {
    return 5;
  }
};

export const formatClockTime = (hour: number, minutes: number): string => {
  return `${String(hour).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
};

// Wednesday, Nov 1 - 8:00 AM - 8:30 AM (Future)
export const formatMeetingSlot = (startDateTime: DateInput, endDateTime: DateInput): string => {
  const ensuredStartDateTime = ensureDate(startDateTime);
  const ensuredEndDateTime = ensureDate(endDateTime);
  const pastLabel = 'Past';
  const futureLabel = 'Future';
  const nowLabel = 'Now';
  const timeLabel = isFuture(ensuredStartDateTime)
    ? futureLabel
    : isPast(ensuredEndDateTime)
      ? pastLabel
      : nowLabel;

  return (
    format(ensuredStartDateTime, `${CALENDAR_DAY}, ${MONTH} ${DATE} - ${TIME_AND_MERIDIEM_UCASE}`) +
    ` - ${format(ensuredEndDateTime, TIME_AND_MERIDIEM_UCASE)} (${timeLabel})`
  );
};
