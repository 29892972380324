import styled from 'styled-components/macro';

import monogramBg from 'assets/bg/monogram-bg.svg';
import { Box } from '@qatalog/mosaiq-ui';

const OnboardingLogoContainer = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-image: url(${monogramBg});
  background-size: min(418px, 100%) auto;
  background-repeat: no-repeat;
  background-position: center;

  ${({ theme }) => theme.mosaiq.mediaQueries.screenSize({ max: 'small' })`
    background-size: 80vw auto;
    padding-top: 70vw;
    margin-top: -70vw;
  `};
`;

export default OnboardingLogoContainer;
