import { Notification } from './types';

export enum NotificationsActionType {
  SET = 'notifications/set',
  SET_NOTIFICATIONS_AS_SEEN = 'notifications/setAsSeen',
}

export interface NotificationsState {
  notifications: Notification[];
  notificationsFetched: boolean;
  newNotificationsCount: number;
}

interface NotificationsAction {
  type: NotificationsActionType;
  notifications?: Notification[];
  newNotificationsCount?: number;
}

const initialState: NotificationsState = {
  notifications: [],
  notificationsFetched: false,
  newNotificationsCount: 0,
};

export default function notificationsReducer(state = initialState, action: NotificationsAction) {
  switch (action.type) {
    case NotificationsActionType.SET: {
      return {
        ...state,
        notifications: action.notifications ?? [],
        notificationsFetched: true,
        newNotificationsCount:
          typeof action.newNotificationsCount === 'number'
            ? action.newNotificationsCount
            : (state.newNotificationsCount ?? 0),
      };
    }

    case NotificationsActionType.SET_NOTIFICATIONS_AS_SEEN: {
      return {
        ...state,
        newNotificationsCount: action.newNotificationsCount ?? 0,
      };
    }

    default:
      return state;
  }
}
