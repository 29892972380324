import { useCallback } from 'react';
import { Location, Params, useLocation, useNavigate, useParams } from 'router';

type LocationObject = {
  pathname?: string;
  search?: string;
  state?: any;
  hash?: string;
};

type pathOrLocationObject = string | LocationObject;

type History = {
  location: Location;
  push: (path: pathOrLocationObject, state?: unknown) => void;
  replace: (path: pathOrLocationObject, state?: unknown) => void;
  goBack: () => void;
  goForward: () => void;
  listen: (cb: (route: string) => void) => () => void;
};

let onRouteChangeCallbacks: any[] = [];

const useHistory = <T>(): History & { params: Readonly<Params<string>> } => {
  const location = useLocation();
  const navigate = useNavigate();
  const params = useParams();

  const locationObjectConverter = useCallback(
    (maybePath: pathOrLocationObject): string => {
      if (typeof maybePath !== 'string') {
        const pathname = `${maybePath?.pathname ? maybePath.pathname : location.pathname}`;
        const hash = `${maybePath?.hash ? `#${maybePath.hash}` : ''}`;
        const search = `${maybePath?.search ? `?${maybePath.search.replace('?', '')}` : ''}`;

        return `${pathname}${hash}${search}`;
      }

      return maybePath;
    },
    [location.pathname],
  );

  const createNavigationParams = useCallback(
    (path: pathOrLocationObject, passedState: unknown) => {
      let url = locationObjectConverter(path);
      let state = {
        ...(typeof passedState === 'object' && passedState),
        ...(typeof path === 'object' && path?.state),
      };
      const hasState = Object.keys(state)?.length > 0;

      return { url, ...(hasState && { state }) };
    },
    [locationObjectConverter],
  );

  return {
    push: useCallback(
      (...args) => {
        const { url, ...rest } = createNavigationParams(...args);

        navigate(url, rest);

        onRouteChangeCallbacks.forEach((cb) => {
          cb(url);
        });
      },
      [navigate, createNavigationParams],
    ),
    replace: useCallback(
      (...args) => {
        const { url, ...rest } = createNavigationParams(...args);

        navigate(url, { ...rest, replace: true });

        onRouteChangeCallbacks.forEach((cb) => {
          cb(url);
        });
      },
      [navigate, createNavigationParams],
    ),
    goBack: () => {
      navigate(-1);
    },
    goForward: () => {
      navigate(1);
    },
    listen: (cb) => {
      if (onRouteChangeCallbacks.every((fn) => fn.toString() !== cb.toString())) {
        onRouteChangeCallbacks.push(cb);
      }

      return () => {
        onRouteChangeCallbacks = onRouteChangeCallbacks.filter(
          (fn) => fn.toString() !== cb.toString(),
        );
      };
    },
    location,
    params,
  };
};

export default useHistory;
