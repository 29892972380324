import { EntityType } from 'mosaiq/types';

import {
  PresearchFilters,
  SearchError,
  SearchQueryHistory,
  SearchResultHistory,
  SearchResultI,
  SearchResultSourceName,
  SelectedTab,
} from '../types';

export enum SearchActionType {
  DEEPEN = 'search/deepen',
  DEEPEN_START = 'search/deepenStart',
  SET = 'search/set',
  UPSERT = 'search/upsert',
  SET_LOADERS = 'search/setLoaders',
  RESET = 'search/reset',
  SET_QUERY = 'search/setQuery',
  SET_QUERY_HISTORY = 'search/setQueryHistory',
  SET_RESULT_HISTORY = 'search/setResultHistory',
  SET_LOADING = 'search/setLoading',
  SET_SEARCH_ID = 'search/setSearchId',
  SET_SELECTED_TAB = 'search/setSelectedTab',
  SET_SELECTED_ENTITIES = 'search/setSelectedEntities',
  SET_SELECTED_PARENTS = 'search/setSelectedParents',
  SET_SELECTED_SORTING = 'search/setSelectedSorting',
  SET_SELECTED_FILTERS = 'search/setSelectedFilters',
  SET_ASSISTANT_SESSION_ID = 'search/setAssistantSessionId',
  SET_PRESEARCH_FILTERS = 'search/setPreSearchFilters',
}

export interface SearchState {
  assistant_session_id?: string;
  results: Record<string, SearchResultI[][]>;
  resultId?: string;
  resultHistory: SearchResultHistory[];
  query: string;
  queryHistory: SearchQueryHistory[];
  loading: Record<string, boolean>;
  searchId?: string;
  selectedTab: SelectedTab;
  selectedEntities: EntityType[];
  selectedParents: String[];
  selectedSorting: string | null;
  filters: Record<string, any>;
  errors: Record<string, SearchError[]>;
  selectedFilters: Record<string, string[]>;
  pagination: Record<
    string,
    {
      offset?: string | number;
      nextPage?: string;
    }
  >;
  preSearchFilters: PresearchFilters | null;
}

interface SearchAction {
  type: SearchActionType;
  assistant_session_id: string;
  results: SearchResultI[];
  resultId?: string;
  resultsType: string;
  resultHistory: SearchResultHistory[];
  query: string;
  queryHistory: SearchQueryHistory[];
  searchId?: string;
  sourceNames: SearchResultSourceName[];
  selectedTab: SelectedTab;
  selectedEntities: EntityType[];
  selectedParents: String[];
  selectedSorting: String | null;
  filters: Record<string, any>;
  errors: SearchError[];
  selectedFilters: Record<string, string[]>;
  pagination: null | {
    offset?: string | number;
    nextPage?: string;
  };
  loaderEnabled: boolean;
  preSearchFilters: PresearchFilters | null;
}

const initialState: SearchState = {
  assistant_session_id: undefined,
  results: {},
  resultHistory: [],
  query: '',
  queryHistory: [],
  loading: {},
  selectedTab: { type: 'all', key: 'all' },
  selectedEntities: [],
  selectedParents: [],
  selectedSorting: null,
  filters: {},
  errors: {},
  selectedFilters: {},
  pagination: {},
  preSearchFilters: null,
};

export default function searchReducer(state = initialState, action: SearchAction) {
  switch (action.type) {
    case SearchActionType.SET: {
      return {
        ...state,
        results: {
          ...state.results,
          [action.resultsType]: [action.results ?? []],
        },
        resultId: action.resultId,
        loading: {
          ...state.loading,
          [action.resultsType]: false,
        },
        filters: {
          ...state.filters,
          [action.resultsType]: action.filters,
        },
        errors: {
          ...state.errors,
          [action.resultsType]: action.errors,
        },
        pagination: {
          ...state.pagination,
          [action.resultsType]: action.pagination,
        },
      };
    }

    case SearchActionType.UPSERT: {
      const resultsArray = state.results[action.resultsType] ?? [];
      resultsArray.push(action.results ?? []);

      return {
        ...state,
        results: {
          ...state.results,
          [action.resultsType]: resultsArray,
        },
        resultId: action.resultId,
        loading: {
          ...state.loading,
          [action.resultsType]: false,
        },
        filters: {
          ...state.filters,
          [action.resultsType]: action.filters,
        },
        errors: {
          ...state.errors,
          [action.resultsType]: action.errors,
        },
        pagination: {
          ...state.pagination,
          [action.resultsType]: action.pagination,
        },
      };
    }

    case SearchActionType.DEEPEN: {
      return {
        ...state,
        results: {
          ...state.results,
          [action.resultsType]: [
            ...new Map(
              [
                ...Object.values(state.results[action.resultsType]).flat(),
                ...(action.results ?? []),
              ].map((item) => [item.source.id || item.source.url, item]),
            ).values(),
          ],
        },
        loading: {
          ...state.loading,
          [action.resultsType]: false,
        },
      };
    }

    case SearchActionType.DEEPEN_START: {
      return {
        ...state,
        loading: {
          ...state.loading,
          [action.resultsType]: true,
        },
      };
    }

    case SearchActionType.RESET: {
      return {
        ...state,
        assistant_session_id: undefined,
        results: {},
        resultId: null,
        query: '',
        loading: {},
        selectedTab: {
          type: 'all',
          key: 'all',
        },
        selectedEntities: [],
        filters: {},
        errors: {},
        selectedFilters: {},
        pagination: {},
      };
    }

    case SearchActionType.SET_LOADERS: {
      return {
        ...state,
        loading: {
          ...state.loading,
          ...(!!action.resultsType
            ? { [action.resultsType]: action.loaderEnabled }
            : action.sourceNames.reduce((acc, sourceName) => {
                acc = {
                  ...acc,
                  [sourceName]: action.loaderEnabled,
                };

                return acc;
              }, {})),
        },
      };
    }

    case SearchActionType.SET_QUERY: {
      return {
        ...state,
        query: action.query ?? '',
        loading: !!action.query
          ? action.sourceNames.reduce((acc, sourceName) => {
              acc = {
                ...acc,
                [sourceName]: true,
              };

              return acc;
            }, {})
          : {},
      };
    }

    case SearchActionType.SET_LOADING: {
      return {
        ...state,
        loading: action.sourceNames.reduce((acc, sourceName) => {
          acc = {
            ...acc,
            [sourceName]: true,
          };

          return acc;
        }, {}),
      };
    }

    case SearchActionType.SET_RESULT_HISTORY: {
      return {
        ...state,
        resultHistory: action.resultHistory ?? [],
      };
    }

    case SearchActionType.SET_QUERY_HISTORY: {
      return {
        ...state,
        queryHistory: action.queryHistory ?? [],
      };
    }

    case SearchActionType.SET_SEARCH_ID: {
      return {
        ...state,
        searchId: action.searchId,
      };
    }

    case SearchActionType.SET_SELECTED_ENTITIES: {
      return {
        ...state,
        selectedEntities: action.selectedEntities ?? [],
      };
    }

    case SearchActionType.SET_SELECTED_PARENTS: {
      return {
        ...state,
        selectedParents: action.selectedParents ?? [],
      };
    }

    case SearchActionType.SET_SELECTED_SORTING: {
      return {
        ...state,
        selectedSorting: action.selectedSorting,
      };
    }

    case SearchActionType.SET_SELECTED_TAB: {
      return {
        ...state,
        selectedTab: action.selectedTab,
        selectedEntities: [],
        selectedFilters: {},
        selectedParents: [],
      };
    }

    case SearchActionType.SET_SELECTED_FILTERS: {
      return {
        ...state,
        selectedFilters: action.selectedFilters ?? [],
      };
    }

    case SearchActionType.SET_ASSISTANT_SESSION_ID: {
      return {
        ...state,
        assistant_session_id: action.searchId,
      };
    }

    case SearchActionType.SET_PRESEARCH_FILTERS: {
      return {
        ...state,
        preSearchFilters: action.preSearchFilters,
      };
    }

    default:
      return state;
  }
}
