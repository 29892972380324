import { getEntityAccessor } from 'store/utils';

const deleteSingleEntity = async ({ state, action }) => {
  const { entityType, entity } = action;

  try {
    const key = getEntityAccessor(entityType);
    if (!key) return state;

    delete state?.[entityType]?.data?.[entity?.[key]];

    return { ...state };
  } catch (_) {
    return state;
  }
};

export default deleteSingleEntity;
