import { useCallback, useState } from 'react';

import { useIntegrationsState } from 'integrations';
import { useMetadataState } from 'store/metadata';

import analytics from 'analytics/analytics';
import eventTypes from 'analytics/events';
import { getUserAnalyticsPayload } from 'analytics/utils';

const useSearchAnalytics = () => {
  const [metadataState] = useMetadataState();
  const [{ connectedIntegrations }] = useIntegrationsState();
  const [eventsWithoutQuery] = useState(new Set([eventTypes.search.SEARCH_FOCUSED]));

  const getAnalyticsPayloadFromSearch = useCallback(
    ({ query, filters, integrations, index, mostRelevant, source } = {}) => {
      return {
        context: 'search',
        index,
        most_relevant: mostRelevant,
        user: getUserAnalyticsPayload(metadataState),
        integration: {
          integration_provider: integrations,
          connected_integrations: connectedIntegrations.size,
        },
        search: {
          search_term: query,
          search_filters: filters,
        },
        source,
      };
    },
    [metadataState, connectedIntegrations.size],
  );

  const trackSearchEvent = useCallback(
    (key, search = {}) => {
      if (eventsWithoutQuery?.has(key) || search.query?.length > 0) {
        const payload = getAnalyticsPayloadFromSearch(search);
        analytics.track(key, payload);
      }
    },
    [eventsWithoutQuery, getAnalyticsPayloadFromSearch],
  );

  return {
    eventTypes: eventTypes.search,
    trackSearchEvent,
  };
};

export default useSearchAnalytics;
