import { useState, useEffect, useCallback, DependencyList, RefObject, useRef } from 'react';
import { useTheme } from 'styled-components/macro';

export const useFixedElement = (limit?: number) => {
  const theme = useTheme();

  const [exceedsLimit, setExceedsLimit] = useState(false);

  const handleResize = () => {
    if (window.innerWidth <= (limit ?? theme.appWidth)) {
      setExceedsLimit(false);
    } else {
      setExceedsLimit(true);
    }
  };

  useEffect(() => {
    handleResize();

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
    // eslint-disable-next-line
  }, []);

  return {
    exceedsLimit,
  };
};

export const useKeypressWithAction = (
  targetKey: string,
  action: (e: KeyboardEvent) => void,
  preventDefault: boolean,
  deps: DependencyList = [],
  options?: { targetRef?: RefObject<any>; hasMetaKey?: boolean },
) => {
  useEffect(() => {
    const handleKeyPress = (e: KeyboardEvent) => {
      const keyPressedMatches = options?.hasMetaKey
        ? (e.metaKey || e.ctrlKey) && e.key === targetKey
        : e.key === targetKey;

      if (keyPressedMatches && action) {
        if (preventDefault) e.preventDefault();
        e.stopPropagation();

        action(e);
      }
    };

    const target = options?.targetRef?.current || window;

    target.addEventListener('keydown', handleKeyPress);

    return () => {
      target.removeEventListener('keydown', handleKeyPress);
    };
    // eslint-disable-next-line
  }, [...deps, options?.targetRef]);
};

export const useDebounce = <T>(value: T, delay: number, initial?: T) => {
  const [debouncedValue, setDebouncedValue] = useState(initial ?? value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [debouncedValue, delay, value]);

  return debouncedValue;
};

export type AsyncReducer<S, A> = (state: S, action: A) => S | Promise<S>;
export type AsyncDispatch<S extends object, A> = (action: A) => Promise<S>;
export const useAsyncReducer = <S extends object, A>(
  reducer: AsyncReducer<S, A>,
  initialState: S | (() => S),
): [S, AsyncDispatch<S, A>] => {
  const [state, setState] = useState<S>(initialState);

  const dispatch: AsyncDispatch<S, A> = useCallback(
    async (action: A) => {
      const result = reducer(state, action);

      let newState = result;

      if (result instanceof Promise || typeof (result as Promise<S>).then === 'function') {
        try {
          newState = await result;
          setState(newState);
        } catch (err) {
          newState = { ...state, error: err };
          setState(newState);
        }
      } else {
        setState(newState as S);
      }

      return newState;
    },
    [reducer, state],
  );

  return [state, dispatch];
};

export const useWindowResize = () => {
  const [windowSize, setWindowSize] = useState(() => ({
    width: window?.innerWidth,
    height: window?.innerHeight,
  }));

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    // Add event listener
    window.addEventListener('resize', handleResize);

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
};

export const useOnClickOutsideWithExclusion = (
  ref: RefObject<Element>,
  handler: (e: PointerEvent) => void,
  excludedSelectors: string[] = [],
) => {
  useEffect(() => {
    const listener = (event: PointerEvent) => {
      // For cases where you have elements e.g modals are inside the target element you want to click outside of.
      let elementsToExclude: Element[] = [];

      excludedSelectors.forEach((target) => {
        elementsToExclude.push(...Array.from(document.querySelectorAll<Element>(target)));
      });

      elementsToExclude = elementsToExclude.filter(Boolean);

      const containsElementsToExclude = elementsToExclude.some(
        (elementToExclude) => event.target && elementToExclude?.contains(event.target as Element),
      );
      // Do nothing if clicking ref's element or descendent elements or a modal inside target element is clicked
      if (
        !ref.current ||
        ref.current.contains(event.target as Element) ||
        containsElementsToExclude
      ) {
        return;
      }

      handler(event);
    };

    document.addEventListener('pointerdown', listener);

    return () => {
      document.removeEventListener('pointerdown', listener);
    };
    // TODO: should we assume single-use and just run it once?
  }, [ref, handler, excludedSelectors]);
};

export const useInterval = (callback: Function, delay?: number | null) => {
  const savedCallback = useRef<Function>(() => {});

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    if (delay !== null) {
      const interval = setInterval(() => savedCallback.current(), delay || 0);
      return () => clearInterval(interval);
    }

    return undefined;
  }, [delay]);
};

export function useIsMounted() {
  const isMounted = useRef(false);

  useEffect(() => {
    isMounted.current = true;

    return () => {
      isMounted.current = false;
    };
  }, []);

  return useCallback(() => isMounted.current, []);
}
