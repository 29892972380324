import React, { createContext, useContext } from 'react';

import { useAsyncReducer } from 'utils/hooks';
import deleteSingle from 'store/reducers/deleteSingleEntity';
import queryList from 'store/reducers/queryListEntities';
import querySingle from 'store/reducers/querySingleEntity';
import { upsertEntitiesToState, resetEntitiesState, upsertEntityToState } from 'store/utils';

const initialState = {};

const entitiesReducer = (state, action) => {
  const reducerMap = {
    deleteSingle,
    queryList,
    querySingle,
    clearState: () =>
      resetEntitiesState({
        state,
        ...action,
      }),
    upsertEntities: () =>
      upsertEntitiesToState({
        state,
        ...action,
      }),
    upsertEntity: () =>
      upsertEntityToState({
        state,
        ...action,
      }),
  };

  return reducerMap[action.type] ? reducerMap[action.type]({ state, action }) : state;
};

export const EntitiesContext = createContext();

export const EntitiesProvider = ({ children }) => {
  const [state, dispatch] = useAsyncReducer(entitiesReducer, initialState);

  return <EntitiesContext.Provider value={[state, dispatch]}>{children}</EntitiesContext.Provider>;
};

export const useEntitiesState = () => useContext(EntitiesContext);
