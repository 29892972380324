import styled from 'styled-components/macro';

interface errorBgColorsMapType {
  [key: string]: string;
}

const errorBgColorsMap: errorBgColorsMapType = {
  '500': '#f9d7e1',
  '404': '#ffebcc',
};

export const ErrorWrapper = styled.div<{ errorCode: string }>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: ${({ theme }) => theme.mosaiq.zIndexes.errorModal};
  background-color: ${({ errorCode }) => errorBgColorsMap[errorCode]};
`;

export const ErrorContainer = styled.div`
  max-width: ${({ theme }) => theme.mosaiq.spacing(151)}px;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow: auto;
  padding: ${({ theme }) => theme.mosaiq.spacing(3)}px ${({ theme }) => theme.mosaiq.spacing(2.5)}px;

  ${({ theme }) =>
    theme.mosaiq.mediaQueries.screenSize({ min: 'small' })`
      padding: ${theme.mosaiq.spacing(8)}px;
    `};
`;

export const ErrorHeader = styled.div`
  img {
    width: ${({ theme }) => theme.mosaiq.spacing(20)}px;
  }
`;

export const ErrorContent = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: ${({ theme }) => theme.mosaiq.spacing(5)}px 0;

  ${({ theme }) =>
    theme.mosaiq.mediaQueries.screenSize({ min: 'small' })`
      flex-direction: row;
      padding-top: ${theme.mosaiq.spacing(6)}px;
      padding-right: 0;
      padding-bottom: ${theme.mosaiq.spacing(6)}px;
      padding-left: ${theme.mosaiq.spacing(12)}px;
    `};
`;

export const ErrorMessage = styled.div`
  margin: -${({ theme }) => theme.mosaiq.spacing(8)}px 0 0;
  text-align: center;
  order: 2;

  ${({ theme }) =>
    theme.mosaiq.mediaQueries.screenSize({ min: 'small' })`
      margin: 0 ${theme.mosaiq.spacing(14.5)}px 0 0;
      text-align: left;
      order: 1;
    `};
`;

export const ErrorTitle = styled.div`
  font-weight: bold;
  font-size: ${({ theme }) => theme.mosaiq.spacing(11)}px;
  line-height: ${({ theme }) => theme.mosaiq.spacing(13)}px;
  margin-bottom: ${({ theme }) => theme.mosaiq.spacing(4)}px;

  ${({ theme }) =>
    theme.mosaiq.mediaQueries.screenSize({ min: 'small' })`
      font-size: ${theme.mosaiq.spacing(20)}px;
      line-height: ${theme.mosaiq.spacing(22.5)}px;
    `};
`;

export const ErrorDescription = styled.div<{ errorCode: string }>`
  font-weight: bold;
  font-size: ${({ errorCode, theme }) => theme.mosaiq.spacing(errorCode === '404' ? 3.75 : 2.5)}px;
  line-height: ${({ errorCode, theme }) => theme.mosaiq.spacing(errorCode === '404' ? 4.5 : 3.5)}px;
  color: #686c73;

  ${({ theme }) =>
    theme.mosaiq.mediaQueries.screenSize({ min: 'small' })`
      margin-left: ${theme.mosaiq.spacing(6)}px;
    `};
`;

export const ErrorActions = styled.div`
  display: inline-flex;
  flex-direction: column;

  & > button {
    margin-top: ${({ theme }) => theme.mosaiq.spacing(3)}px;
    justify-content: center;
  }

  ${({ theme }) =>
    theme.mosaiq.mediaQueries.screenSize({ min: 'small' })`
      display: flex;
      flex-direction: row;
      margin: ${theme.mosaiq.spacing(5)}px 0 0 ${theme.mosaiq.spacing(6)}px;

      & > button {
        margin-top: 0;
        margin-right: ${theme.mosaiq.spacing(3)}px;
      }
    `};
`;

export const ErrorIllustration = styled.div`
  display: flex;
  align-items: center;
  order: 1;

  img {
    width: 100%;
  }

  ${({ theme }) =>
    theme.mosaiq.mediaQueries.screenSize({ min: 'small' })`
      order: 2;

      img {
        width: ${theme.mosaiq.spacing(50)}px;
        margin-top: ${theme.mosaiq.spacing(12.5)}px;
      }
    `};
`;
