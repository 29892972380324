import analytics from 'analytics/analytics';
import eventTypes from 'analytics/events';

const useAnalytics = () => {
  return {
    eventTypes: eventTypes,
    trackEvent: analytics.track,
    trackPageView: analytics.page,
  };
};

export default useAnalytics;
