import { combineReducers } from 'redux';

import { sidebarReducer } from 'sidebar';

import { notificationsReducer } from 'mosaiq/notifications';
import mosaiqSidebarReducer from 'mosaiq/sidebar/sidebarReducer';
import { searchReducer as mosaiqSearchReducer } from 'mosaiq/search/store';

import threadsViewReducer from 'mosaiq/threads/views/ThreadsView/threadsViewReducer';

const rootReducer = combineReducers({
  sidebar: sidebarReducer,
  mosaiqSidebar: mosaiqSidebarReducer,
  mosaiqSearch: mosaiqSearchReducer,
  notifications: notificationsReducer,
  threadsView: threadsViewReducer,
});

export default rootReducer;
