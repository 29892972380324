interface messagesType {
  [key: string]: {
    title: string;
    description: string;
    dashboardButton: string;
    helpButton: string;
  };
}

export const messages: messagesType = {
  '401': {
    title: 'Uh oh',
    description:
      'It looks like you don’t have access to this page. If you think this is in error, please contact your administrator.',
    dashboardButton: 'Back to website',
    helpButton: 'Chat with us to get immediate help',
  },
  '404': {
    title: 'Ooops!',
    description: 'Delta 404, we can’t locate the page you’re looking for.',
    dashboardButton: 'Back to dashboard',
    helpButton: 'Chat with us to get immediate help',
  },
  '500': {
    title: 'Ouch!',
    description:
      'Tower to airliner, we’re experiencing an internal server error. Our team is aware and working to fix the issue — apologies for any inconvenience.',
    dashboardButton: 'Back to dashboard',
    helpButton: 'Chat with us to get immediate help',
  },
  default: {
    title: 'Ouch!',
    description:
      'Tower to airliner, we’re experiencing an internal server error. Our team is aware and working to fix the issue — apologies for any inconvenience.',
    dashboardButton: 'Back to dashboard',
    helpButton: 'Chat with us to get immediate help',
  },
};
