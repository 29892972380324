import createStateContext from '../store/state';
import { dispatchIntercept } from '../store/middleware';
import { ExternalEntityType, Integration } from './types';

type IntegrationsState = {
  connectedIntegrations: Map<ExternalEntityType, Integration>;
  connectedIntegrationsFetched: boolean;
  connectedIntegrationsFetchError: any;
};

const initialState = {
  connectedIntegrations: new Map<ExternalEntityType, Integration>(),
  connectedIntegrationsFetched: false,
  connectedIntegrationsFetchError: null,
};

const setConnectedIntegrations = ({
  state,
  action,
}: {
  state: IntegrationsState;
  action: {
    connectedIntegrations: Map<ExternalEntityType, Integration>;
    integrationFetchError: any;
  };
}) => ({
  ...state,
  connectedIntegrations: new Map(action.connectedIntegrations),
  connectedIntegrationsFetched: true,
  connectedIntegrationsFetchError: action.integrationFetchError,
});

const integrationsReducer = (
  state: IntegrationsState,
  action: {
    type: 'setConnectedIntegrations';
    connectedIntegrations: Map<ExternalEntityType, Integration>;
    integrationFetchError: any;
  },
) => {
  const reducerMap = {
    setConnectedIntegrations,
  };

  return reducerMap[action.type] ? reducerMap[action.type]({ state, action }) : state;
};

const {
  useStateValue: useIntegrationsState,
  StateProvider: IntegrationsProvider,
  StateContext: IntegrationsContext,
} = createStateContext<IntegrationsState>(integrationsReducer, initialState, dispatchIntercept);

const DISPLAY_NAME = 'IntegrationsState';

IntegrationsContext.displayName = DISPLAY_NAME;
IntegrationsProvider.displayName = DISPLAY_NAME;

export { useIntegrationsState, IntegrationsProvider, IntegrationsContext };
