// this file stores global config settings
// these can be modified based on env and/or platforms.

const systemConfig = {
  ext: {
    AddThis: '//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5f0d84df65878bef',
    typeform: {
      earlyAccess: 'https://qatalog.typeform.com/to/whwhAPZg',
    },
  },
  authRoutes: {
    oauth: 'oauth',
    checkAuth: 'isauthenticated',
    logout: 'logout',
  },
  containers: {
    people: {
      defaultView: 'table',
      maxTableRows: 20,
    },
  },
  resolvedLanguage: 'english',
  acceptableLanguages: ['english'],
  ignoredExceptionMessages: [
    /^ChunkLoadError: Loading chunk \d+ failed\..+\(timeout:.https:\/\/assets\.qatalog\.com/ims,
    /^UNHANDLED PROMISE REJECTION: {"isTrusted":(true|false),"reason":({}|"Object Not Found Matching Id:\d+")[^]+/im,
    /^Network Error/im,
  ],
  ignoredExceptionUrls: [
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    /^chrome-extension:\/\//i,
  ],
  unsplashCollections: {
    teams: '42793295',
    projects: '25844976',
    workflows: '13551606',
  },
} as const;

export default systemConfig;
