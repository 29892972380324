import client from 'utils/client';

import { resetEntitiesState, upsertEntitiesToState } from 'store/utils';

const queryListEntities = async ({ state, action }) => {
  const {
    entityType,
    shouldOverwrite,
    query,
    variables,
    parseData = (data) => data[entityType],
  } = action;

  try {
    const { data, errors } = await client.query({
      query,
      variables,
    });

    if (errors?.length) {
      throw errors[0];
    }

    const entities = parseData(data);

    if (!data.meta) {
      // HACK: For endpoints that don't return a `count`, assume that
      // less results than you requested means that is the last page.
      // This shouldn't hurt anything...?
      data.meta = {
        exhausted: (entities?.length || 0) < (variables?.limit || Infinity),
      };
    }

    return upsertEntitiesToState({
      data,
      state,
      entityType,
      entities,
      shouldOverwrite,
    });
  } catch (error) {
    return resetEntitiesState({
      state,
      entityType,
      error,
    });
  }
};

export default queryListEntities;
