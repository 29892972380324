import styled, { css, FlattenSimpleInterpolation, StyledProps } from 'styled-components/macro';

export const SidebarOverlay = styled.div<{
  $active?: boolean;
  $direction?: string;
  $offsets?: {
    top?: number;
    right?: number;
    bottom?: number;
    left?: number;
  };
}>`
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, ${({ $active }) => ($active ? 0.6 : 0)});
  transform: translate3d(0, 0, 0);
  opacity: 1;
  transition: background 0.2s ease;
  z-index: ${({ theme }) => theme.mosaiq.zIndexes.sidebar};
  top: 0;
  left: ${({ $offsets }) => $offsets?.left ?? 0}px;
  overflow: hidden;

  ${({ theme }) => theme.mosaiq.mediaQueries.screenSize({ max: 'medium' })`
    left: 0;
  `};

  /* Fix dialog width if its inside the side panel */
  .confirm-dialog #hyper-modal-wrapper_component_id > div {
    width: 100%;
  }
`;

export type Direction = 'ltr' | 'rtl';

export interface TranslateProps {
  $active: boolean;
  $activeTranslate?: string;
  $inactiveTranslate?: string;
  $width?: number;
  $direction?: Direction;
}

export interface DirectionProps {
  $direction?: Direction;
}

export interface SidebarWrapperProps {}

const getTransformValues = ({
  $active,
  $activeTranslate,
  $inactiveTranslate,
  $width,
  $direction = 'rtl',
}: TranslateProps) => {
  if ($active) {
    if ($activeTranslate) {
      return css`
        ${$activeTranslate}
      `;
    } else {
      return `translate3d(0, 0, 0);`;
    }
  }

  if ($direction === 'ltr') {
    return $inactiveTranslate || `translate3d(-${$width}px, 0, 0);`;
  } else {
    return $inactiveTranslate || `translate3d(${$width}px, 0, 0);`;
  }
};

const getPosition = ({ $direction = 'rtl' }: DirectionProps) => {
  if ($direction === 'ltr') {
    return css`
      left: 0;
      top: 0;
    `;
  } else {
    return css`
      right: 0;
      top: 0;
    `;
  }
};

const getBorder = ({
  theme,
  $direction = 'rtl',
}: StyledProps<DirectionProps>): FlattenSimpleInterpolation => {
  if ($direction === 'ltr') {
    return css`
      border-right: thin solid ${theme.colors.grey20};
    `;
  } else {
    return css`
      border-left: thin solid ${theme.colors.grey20};
    `;
  }
};

const getTranslate = (index: number, activeIndex: boolean) => {
  if ((activeIndex && index === 0) || activeIndex) {
    return `translate3d(0, 0, 0)`;
  } else {
    return `translate3d(-16px, 0, 0)`;
  }
};

export const SidebarBox = styled.div<{
  $index: number;
  $activeIndex: boolean;
}>`
  transition: all 0.1s ease-out ${({ $activeIndex }) => ($activeIndex ? '0' : '0.2')}s;
  transform: ${({ $index, $activeIndex }) => getTranslate($index, $activeIndex)};
  height: 100%;
  overflow-y: auto;

  & > div {
    width: 100%;
    height: 100%;
  }

  header {
    padding: ${({ theme }) => theme.spacing(3)}px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${({ theme }) => theme.typography.title4()};

    button {
      margin-right: ${({ theme }) => theme.spacing(2)}px;
    }
  }

  > section {
    padding: ${({ theme }) => theme.spacing(3)}px;
  }
`;

export const SidebarWrapper = styled.div<TranslateProps>`
  background: ${({ theme }) => theme.colors.white};
  box-shadow: ${({ theme }) => theme.elevation[2]};
  position: fixed;

  width: ${({ $active, $width }) => ($active ? $width : $width)}px;
  min-width: ${({ $active, $width }) => ($active ? $width : $width)}px;
  height: 100%;
  transition: ${({ $active }) => ($active ? 'all 0.3s ease-out 0.1s' : 'all 0.3s ease-out 0.1s')};
  transform: ${getTransformValues};

  ${({ $direction }) => getPosition({ $direction })};
  ${({ $direction, theme }) => getBorder({ $direction, theme })};

  display: flex;
  flex-direction: column;
  overflow: hidden;

  ${({ theme }) => theme.mosaiq.mediaQueries.screenSize({ max: 'medium' })`
    width: 100%;
    min-width: 100%;
  `};
`;
