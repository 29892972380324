import axios from 'axios';
import { PropsWithChildren, useLayoutEffect } from 'react';

import config from 'config';
import { useHistory } from 'router';

import LoadingApp from 'components/LoadingApp/LoadingApp';

const CustomDomainAuthentication = ({ children }: PropsWithChildren) => {
  const isCustomDomainAuthNeeded = window.location.href?.includes('login?cdat=');
  const history = useHistory();

  const customDomainAuthRedirect = async () => {
    const url = new URL(window.location.href);
    const cdat = url.searchParams.get('cdat');
    try {
      const { data: isAuthenticated } = await axios.get(
        config.envConfig.endpoints.auth.customDomainAuthentication,
        {
          params: { cdat },
        },
      );

      if (isAuthenticated) {
        // @ts-ignore
        window.location = `https://${window.location.host}/`;
      }
    } catch (_) {
      history.push('/login');
    }
  };

  useLayoutEffect(() => {
    if (isCustomDomainAuthNeeded) {
      customDomainAuthRedirect();
    }
  }, [window.location.href]);

  if (!isCustomDomainAuthNeeded) return children;

  return <LoadingApp />;
};

export default CustomDomainAuthentication;
