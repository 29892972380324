import 'react-toastify/dist/ReactToastify.css';
import { createGlobalStyle } from 'styled-components/macro';

export const ToastifyStyles = createGlobalStyle`
  :root {
    --toastify-color-success: ${({ theme }) => theme.mosaiq.colors.success};
    --toastify-color-warning: ${({ theme }) => theme.mosaiq.colors.warning};
    --toastify-color-error: ${({ theme }) => theme.mosaiq.colors.error};
    --toastify-color-info: ${({ theme }) => theme.mosaiq.colors.action};
  }

 @keyframes Toastify__trackProgress {
    0% {
      transform: scaleX(1);
    }
    100% {
      transform: scaleX(0);
    }
  }

  .Toastify__toast-container {
    min-width: 400px;
    width: auto;
    z-index: ${({ theme }) => theme.mosaiq.zIndexes.toast};
    position: fixed;
    padding: 4px;

    ${({ theme }) => theme.mosaiq.mediaQueries.screenSize({ max: 'extraSmall' })`
      width: 100vw;
      min-width: 100vw;
      padding: 1em;
    `}
  }

  .Toastify__toast-container--top-left {
    top: 1em;
    left: 4em;

    /* Account for lack of left-nav */
    ${({ theme }) => theme.mosaiq.mediaQueries.screenSize({ max: 'small' })`
      left: 1em;
    `}
  }

  .Toastify__toast-container--top-center {
    top: 1em;
    left: 50%;
    transform: translateX(-50%);
  }

  .Toastify__toast-container--top-right {
    top: 1em;
    right: 1em;
  }

  .Toastify__toast-container--bottom-left {
    bottom: 1em;
    left: 4em;

    /* Account for lack of left-nav */
    ${({ theme }) => theme.mosaiq.mediaQueries.screenSize({ max: 'small' })`
      left: 1em;
    `}
  }

  .Toastify__toast-container--bottom-center {
    bottom: 1em;
    left: 50%;
    transform: translateX(-50%);
  }

  .Toastify__toast-container--bottom-right {
    bottom: 1em;
    right: 1em;
  }

  @media only screen and (max-width: 480px) {
    .Toastify__toast-container {
      width: 100vw;
      padding: 0;
      left: 0;
      margin: 0;
    }

    .Toastify__toast-container--top-left,
    .Toastify__toast-container--top-center,
    .Toastify__toast-container--top-right {
      top: 0;
      transform: translateX(0);
    }

    .Toastify__toast-container--bottom-left,
    .Toastify__toast-container--bottom-center,
    .Toastify__toast-container--bottom-right {
      bottom: 0;
      transform: translateX(0);
    }

    .Toastify__toast-container--rtl {
      right: 0;
      left: initial;
    }
  }

  .Toastify__toast {
    position: relative;
    margin-bottom: 1em;
    padding: 0;
    min-height: unset;
    border-radius: ${({ theme }) => theme.spacing(1)}px;
    box-sizing: border-box;
    box-shadow: 0 1px 10px 0 rgba(0, 0, 0, 0.1), 0 2px 15px 0 rgba(0, 0, 0, 0.05);
    max-height: 800px;
    overflow: hidden;
    cursor: pointer;
    direction: ltr;

    .Toastify__toast-body {
      max-width: 100%;
      margin: 0;
      padding: 0;
      background: none;
    }

    ${({ theme }) => theme.mosaiq.mediaQueries.screenSize({ max: 'extraSmall' })`
      margin: 1em;
    `}
  }

  .Toastify__progress-bar {
    position: absolute;
    bottom: 0;
    height: 4px;
    width: 100%;
    z-index: ${({ theme }) => theme.mosaiq.zIndexes.toast + 1};
    opacity: 1;
    transform-origin: left;

    .Toastify__progress-bar--animated {
      animation: Toastify__trackProgress linear 1 forwards;
    }

    .Toastify__progress-bar--controlled {
      transition: transform 0.2s;
    }

    .Toastify__progress-bar--rtl {
      right: 0;
      left: initial;
      transform-origin: right;
    }
  }
`;
