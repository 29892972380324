import { useState, useEffect, useRef, useCallback } from 'react';
import axios, { AxiosResponse, Method } from 'axios';
import { isEqual } from 'lodash';

import { useEffectOnce } from 'hooks';

export type UseRESTQueryProps<D = {}, P = {}> = {
  url: string;
  method?: Method;
  data?: D;
  params?: P;
  skip?: boolean;
};

export type UseRESTQueryResponse<T, D = {}, P = {}> = Partial<AxiosResponse<T, D>> & {
  loading: boolean;
  refetch: (payload?: D | null | undefined, params?: P | null | undefined) => Promise<void>;
};

/**
 * A useQuery-like interface to REST endpoints, exposing loading state and
 * status, intelligent requery based on changed props, and the ability to
 * defer calls.
 */
export const useRESTQuery = <T = any, D extends object = {}, P extends object = {}>({
  url,
  method = 'GET',
  data,
  params,
  skip = false,
}: UseRESTQueryProps<D, P>): UseRESTQueryResponse<T, D, P> => {
  const mountedRef = useRef<boolean>(true);
  const initFetchedRef = useRef<boolean>(false);
  const dataRef = useRef<D | undefined>();
  const skipRef = useRef<boolean>(skip);

  const [response, setResponse] = useState<AxiosResponse<T, D> | undefined>();
  const [loading, setLoading] = useState<boolean>(!skip);

  const fetch = useCallback(
    async (payload?: D | null, extraParams?: P | null) => {
      if (!mountedRef.current) return;
      if (skip) return;

      setLoading(true);
      try {
        const finalPayload = !!payload && !!data ? { ...data, ...payload } : (payload ?? data);
        const finalParams =
          !!extraParams && !!params ? { ...params, ...extraParams } : (extraParams ?? params);

        const res = (await axios({
          url,
          method,
          data: finalPayload,
          params: finalParams,
        })) as AxiosResponse<T, D>;
        if (mountedRef.current) {
          setResponse(res);
        }
      } catch (_) {
      } finally {
        if (mountedRef.current) {
          setLoading(false);
        }
      }
    },
    [data, params, url, method, skip],
  );

  useEffect(() => {
    const dataChanged = !isEqual(dataRef.current, data);
    const skipChanged = skip !== skipRef.current;
    if (dataChanged || !initFetchedRef.current || (skipChanged && !skip)) {
      initFetchedRef.current = true;
      fetch();
    }

    dataRef.current = data;
    skipRef.current = skip;
  }, [fetch, data, skip]);

  useEffectOnce(() => {
    mountedRef.current = true;

    return () => {
      mountedRef.current = false;
    };
  });

  return {
    ...response,
    loading,
    refetch: fetch,
  };
};
