import styled from 'styled-components/macro';

export const Card = styled.div`
  display: grid;
  grid-template-columns: 18px 1fr;
  gap: ${({ theme }) => theme.spacing(1)}px;
  padding: ${({ theme }) => theme.spacing(2)}px;
  margin: ${({ theme }) => theme.spacing(2)}px;
  background: ${(props) => props.theme.colors.error};
  color: ${(props) => props.theme.colors.white};
  border-radius: ${({ theme }) => theme.spacing(1)}px;
`;
