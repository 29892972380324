import {
  FC,
  createContext,
  useReducer,
  useContext,
  Reducer,
  useCallback,
  PropsWithChildren,
} from 'react';

import { useLockBodyScroll } from 'utils/overlays';
import { useSearchAnalytics } from 'analytics';

export interface QuickSearchState {
  open: boolean;
}

export interface QuickSearchAction {
  type: QuickSearchActionType;
}

export interface QuickSearchContextValue extends QuickSearchState {
  openQuickSearch: () => void;
  closeQuickSearch: () => void;
}

enum QuickSearchActionType {
  OPEN_QUICK_SEARCH = 'openQuickSearch',
  CLOSE_QUICK_SEARCH = 'closeQuickSearch',
}

const initialState: QuickSearchState = {
  open: false,
};

const quickSearchReducer: Reducer<QuickSearchState, QuickSearchAction> = (state, action) => {
  switch (action.type) {
    case QuickSearchActionType.OPEN_QUICK_SEARCH:
      return {
        ...state,
        open: true,
      };

    case QuickSearchActionType.CLOSE_QUICK_SEARCH:
      return {
        ...state,
        open: false,
      };

    default:
      return state;
  }
};

export const QuickSearchContext = createContext<QuickSearchContextValue>({
  ...initialState,
} as QuickSearchContextValue);

export const useQuickSearch = () => useContext(QuickSearchContext);

export const QuickSearchProvider: FC<PropsWithChildren> = ({ children }) => {
  const { eventTypes: searchEventTypes, trackSearchEvent } = useSearchAnalytics();
  const [state, dispatch] = useReducer(quickSearchReducer, initialState);
  useLockBodyScroll(state.open);

  const closeQuickSearch = useCallback(() => {
    dispatch({
      type: QuickSearchActionType.CLOSE_QUICK_SEARCH,
    });
  }, []);

  const openQuickSearch = useCallback(() => {
    trackSearchEvent(searchEventTypes.SEARCH_FOCUSED, { source: 'quick' });

    dispatch({
      type: QuickSearchActionType.OPEN_QUICK_SEARCH,
    });
  }, []);

  return (
    <QuickSearchContext.Provider
      value={{
        ...state,
        closeQuickSearch,
        openQuickSearch,
      }}
    >
      {children}
    </QuickSearchContext.Provider>
  );
};
