import { createGlobalStyle } from 'styled-components/macro';
import { rgba } from 'polished';

export const MediumZoomedImage = createGlobalStyle`
.medium-zoom-overlay, .medium-zoom-image.medium-zoom-image--opened {
  z-index: ${({ theme }) => theme.mosaiq.zIndexes.imagezoom};
}

.medium-zoom-overlay {
  background: ${({ theme }) => rgba(theme.colors.black, 0.6)} !important;
  backdrop-filter: blur(10px);
}
`;
