import { InputHTMLAttributes, forwardRef, ForwardedRef, useState } from 'react';
import { kebabCase, uniqueId } from 'lodash';

import OnboardingLabel from '../OnboardingLabel';

import { OnboardingInputField } from './OnboardingInput.styles';

interface OnboardingInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
}

const OnboardingInput = (
  { label, ...props }: OnboardingInputProps,
  ref: ForwardedRef<HTMLInputElement>,
) => {
  const [inputId] = useState(uniqueId(kebabCase(label).substring(0, 10)));

  return (
    <div>
      <label htmlFor={inputId}>
        <OnboardingLabel data-testid="onboardingLabel">{label}</OnboardingLabel>
      </label>
      <OnboardingInputField
        data-testid="onboardingInputField"
        ref={ref}
        autoFocus
        {...props}
        id={inputId}
      />
    </div>
  );
};

export default forwardRef(OnboardingInput);
