import { EntityType } from 'types';
import { Thread } from 'mosaiq/threads/types';
import type { ThreadsTypeFilter, ThreadParent, DraftThreadProps } from 'mosaiq/threads/types';

export enum ThreadsViewActionType {
  SET_THREADS_VIEW = 'threadsView/setThreadsView',
  SET_THREAD_TYPES = 'threadsView/setThreadTypes',
  SET_THREADS = 'threadsView/setThreads',
  SET_THREAD = 'threadsView/setThread',
  SET_DRAFTS = 'threadsView/setDrafts',

  SET_THREAD_ID = 'threadsView/setThreadId',
  SET_PARENT = 'threadsView/setParentEntity',
  SET_CREATE = 'threadsView/setCreate',
  SET_EDIT = 'threadsView/setEdit',
  SET_DIRTY = 'threadsView/setDirty',

  SET_FILTER = 'threadsView/setFilter',
  SET_SEARCH = 'threadsView/setSearch',
  SET_SIDEBAR_OPEN = 'threadsView/setSidebarOpen',
  SET_THREAD_TYPE = 'threadsView/setThreadType',
  SET_VIEWPORT_HEIGHT = 'threadsView/setViewportHeight',

  RESET_STATE = 'threadsView/resetState',
}

interface ThreadsAction {
  type: ThreadsViewActionType;
  parentEntityProps: {
    entity: ThreadParent | null;
    entityType: EntityType;
  };
  edit: boolean;
  create: boolean;
  threadId: string;
  search: string;
  threadsFilter: ThreadsTypeFilter;
  sidebarOpen: boolean;
  dirty: boolean;
  threadTypes: {
    label: string;
    value: string;
    needs_resolution?: boolean;
  }[];
  threadType: {
    label: string;
    value: string;
    needs_resolution?: boolean;
  } | null;
  threads: Thread[];
  drafts: DraftThreadProps | null;
  viewportHeight: number;
}

export interface ThreadViewState {
  parentEntity?: ThreadParent | null;
  edit: boolean;
  create: boolean;
  threadId?: string;
  search: string;
  threadsFilter: ThreadsTypeFilter;
  threadTypes: {
    label: string;
    value: string;
    needs_resolution?: boolean;
  }[];
  threadType: {
    label: string;
    value: string;
    needs_resolution?: boolean;
  } | null;
  sidebarOpen: boolean;
  dirty: boolean;
  threads: Thread[];
  drafts?: DraftThreadProps | null;
  viewportHeight: number;
}

const initialState: ThreadViewState = {
  threadId: undefined,
  parentEntity: null,
  edit: false,
  create: false,
  search: '',
  threadsFilter: {
    key: 'active',
    label: 'Active',
  },
  threadTypes: [],
  threadType: null,
  sidebarOpen: true,
  dirty: false,
  threads: [],
  drafts: null,
  viewportHeight: window.innerHeight,
};

export default function threadsViewReducer(state = initialState, action: ThreadsAction) {
  switch (action.type) {
    case ThreadsViewActionType.SET_THREAD_ID: {
      return {
        ...state,
        threadId: action.threadId,
      };
    }

    case ThreadsViewActionType.SET_THREADS_VIEW: {
      return {
        ...state,
        threadId: action.threadId,
        edit: action.edit,
        create: action.create,
      };
    }

    case ThreadsViewActionType.SET_PARENT: {
      return {
        ...state,
        parentEntity: action.parentEntityProps,
      };
    }

    case ThreadsViewActionType.SET_THREAD_TYPES: {
      return {
        ...state,
        threadTypes: action.threadTypes,
      };
    }

    case ThreadsViewActionType.SET_THREAD_TYPE: {
      return {
        ...state,
        threadType: action.threadType,
      };
    }

    case ThreadsViewActionType.SET_FILTER: {
      return {
        ...state,
        threadsFilter: action.threadsFilter,
      };
    }

    case ThreadsViewActionType.SET_CREATE: {
      return {
        ...state,
        create: action.create,
      };
    }

    case ThreadsViewActionType.SET_EDIT: {
      return {
        ...state,
        edit: action.edit,
      };
    }

    case ThreadsViewActionType.SET_SEARCH: {
      return {
        ...state,
        search: action.search,
      };
    }

    case ThreadsViewActionType.SET_SIDEBAR_OPEN: {
      return {
        ...state,
        sidebarOpen: action.sidebarOpen,
      };
    }

    case ThreadsViewActionType.SET_DIRTY: {
      return {
        ...state,
        dirty: action.dirty,
      };
    }

    case ThreadsViewActionType.SET_THREADS: {
      return {
        ...state,
        threads: action.threads,
      };
    }

    case ThreadsViewActionType.SET_DRAFTS: {
      return {
        ...state,
        drafts: action.drafts,
      };
    }

    case ThreadsViewActionType.SET_VIEWPORT_HEIGHT: {
      return {
        ...state,
        viewportHeight: action.viewportHeight,
      };
    }

    case ThreadsViewActionType.RESET_STATE: {
      return initialState;
    }

    default:
      return state;
  }
}
