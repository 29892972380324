import { useEffect, useState } from 'react';
import { NavLink, useHistory } from 'router';

import { Button, ButtonProps } from '@qatalog/mosaiq-ui';

import icon__qatalog from 'assets/icons/q-monogram-with-text-dark.svg';
import { toggleInAppHelp } from 'utils/in-app-help';

import { messages } from './utils';
import {
  ErrorActions,
  ErrorContainer,
  ErrorContent,
  ErrorDescription,
  ErrorHeader,
  ErrorIllustration,
  ErrorMessage,
  ErrorTitle,
  ErrorWrapper,
} from './Error.styles';

interface ErrorProps {
  status?: string;
}

const Error = ({ status = '404' }: ErrorProps) => {
  const history = useHistory();

  const searchParams = new URLSearchParams(history.location.search || window.location.search);

  const errorCode = searchParams.get('status') || status;

  const strings = messages[errorCode] || messages.default;

  const [errorIllustration, setErrorIllustration] = useState();

  const errorActions = ['401', '404', '500'].reduce(
    (ea: { [key: string]: ButtonProps[] }, status) => {
      ea[status] = [
        {
          color: 'primary',
          children: strings.dashboardButton,
          onClick: () => {
            history.push({
              pathname: '/',
            });
          },
        },
        {
          color: 'primary',
          children: strings.helpButton,
          variant: 'secondary',
          onClick: toggleInAppHelp,
        },
      ];
      return ea;
    },
    {},
  );

  useEffect(() => {
    (async () => {
      try {
        const img = await import(`../../../assets/illustrations/${errorCode}.png`);
        img && setErrorIllustration(img.default);
      } catch (_) {}
    })();
    // eslint-disable-next-line
  }, []);

  return (
    <ErrorWrapper errorCode={errorCode}>
      <ErrorContainer>
        <ErrorHeader>
          <NavLink to="/" end>
            <img src={icon__qatalog} alt={'app logo'} />
          </NavLink>
        </ErrorHeader>
        <ErrorContent>
          <ErrorMessage>
            <ErrorTitle>{strings.title}</ErrorTitle>
            <ErrorDescription errorCode={errorCode}>{strings.description}</ErrorDescription>
            <ErrorActions>
              {errorActions[errorCode] &&
                errorActions[errorCode].map((action, i: number) => <Button key={i} {...action} />)}
            </ErrorActions>
          </ErrorMessage>
          {errorIllustration ? (
            <ErrorIllustration>
              <img src={errorIllustration} alt={`${errorCode} illustration`} />
            </ErrorIllustration>
          ) : null}
        </ErrorContent>
      </ErrorContainer>
    </ErrorWrapper>
  );
};

export default Error;
