import styled, { css } from 'styled-components/macro';
import { ellipsis } from 'polished';
import { Button } from '@qatalog/mosaiq-ui';

export const OnboardingLogout = styled.div`
  position: fixed;
  top: ${({ theme }) => theme.spacing(5.75)}px;
  right: ${({ theme }) => theme.spacing(6)}px;
  z-index: 10;

  ${({ theme }) => theme.mosaiq.mediaQueries.screenSize({ max: 'small' })`
    top: ${theme.spacing(2.75)}px;
    right: ${theme.spacing(2.5)}px;
  `};
`;

export const OnboardingActions = styled.div<{ multiple?: boolean }>`
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0;
  padding: ${({ theme }) => theme.spacing(6)}px;
  z-index: 10;
  display: flex;
  justify-content: ${({ multiple }) => (multiple ? 'space-between' : 'flex-end')};
  gap: ${({ theme }) => theme.spacing(1)}px;

  ${({ theme }) => theme.mosaiq.mediaQueries.screenSize({ max: 'extraSmall' })`
    padding: ${theme.spacing(3)}px;

    button {
      width: auto;
      display: flex;
      justify-content: center;
      width: 100%;

      span {
        ${ellipsis(undefined, 1)}
      }
    }
  `};
`;

export const WhiteButton = styled(Button).attrs({ variant: 'secondary' })`
  ${({ active }) =>
    !active &&
    css`
      background-color: ${({ theme }) => theme.mosaiq.colors.white};
    `}
`;
