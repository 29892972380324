import { useCallback } from 'react';

import analytics from 'analytics/analytics';
import eventTypes from 'analytics/events';

const useSupportAnalytics = () => {
  const trackSupportEvent = useCallback((key, payload) => {
    analytics.track(key, payload);
  }, []);

  return {
    eventTypes: eventTypes.support,
    trackSupportEvent,
  };
};

export default useSupportAnalytics;
