import React, { FC, PropsWithChildren } from 'react';
import { useMetadataState } from 'store/metadata';
import { StatsigProvider, StatsigEnvironment } from 'statsig-react';
import { daysBetweenTwoDates, datestampToLocalDate } from 'utils/datetime';
import LoadingApp from 'components/LoadingApp/LoadingApp';

const STATSIG_ENV_MAP: { [key: string]: StatsigEnvironment['tier'] } = {
  local: 'development',
  test: 'development',
  integration: 'staging',
  development: 'development',
  production: 'production',
};

const StatsigWrapper: FC<PropsWithChildren> = ({ children }) => {
  const [metadata] = useMetadataState<'unknown'>();

  if (!metadata?.statsig_client_id) {
    return <>{children}</>;
  }

  const env = (metadata?.environment ?? process.env.NODE_ENV) as keyof typeof STATSIG_ENV_MAP;
  const orgCreatedAt = metadata?.org?.created_at
    ? new Date(metadata.org.created_at).getTime().toString()
    : null;

  return (
    <StatsigProvider
      sdkKey={metadata?.statsig_client_id}
      user={{
        userID: metadata?.person?.id,
        email: metadata?.person?.email_address,
        customIDs: {
          orgCreatedAt: orgCreatedAt ?? '',
          orgId: metadata?.org?.id ?? null,
        },
        custom: {
          orgId: metadata?.org?.id ?? null,
          seats: metadata?.org?.person_count ?? 0,
          orgRole: metadata?.person?.org_role,
          completedOnboarding: Boolean(metadata?.is_signed_up),
          daysSinceSignup: daysBetweenTwoDates(
            datestampToLocalDate(metadata.person?.signed_up_date)!,
            Date.now(),
          ),
          payPlan: metadata?.org?.billing_plan_name,
          orgCreatedAt: orgCreatedAt ?? '',
        },
      }}
      options={{
        api: '/api/feature-flags',
        environment: {
          tier: STATSIG_ENV_MAP[env],
        },
      }}
      waitForInitialization={true}
      initializingComponent={<LoadingApp />}
    >
      {children}
    </StatsigProvider>
  );
};

export default StatsigWrapper;
