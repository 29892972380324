import { Typography } from '@qatalog/mosaiq-ui';
import { WarningCircle } from 'phosphor-react';
import { translateErrno } from 'utils/errors';

import { Card } from './ErrorCard.styles';

const ErrorCard = ({ error }) => {
  const networkError = error?.networkError?.result;
  const graphQLError = error?.graphQLErrors?.[0]?.error?.extensions?.exception?.output?.payload;

  const cardError = graphQLError || networkError || error;
  let errorMessage;

  if (cardError.errno) {
    errorMessage = translateErrno(cardError);
  } else {
    errorMessage = error?.message || 'An error occurred';
  }

  return (
    <Card>
      <WarningCircle />
      <Typography variant="body3">{errorMessage}</Typography>
    </Card>
  );
};

export default ErrorCard;
