// Previously we used lockr to stash PII in localStorage. We want to use
// sessionStorage for that stuff instead and lockr doesn't support it.
// But the storage API is tiny, so implement our own lockr-compatible
// interface over sessionStorage.

const hasStorage = (() => {
  const uid = new Date().getTime().toString();
  let localstore;
  let sessionstore;

  try {
    (localstore = window.localStorage).setItem(uid, uid);
    (sessionstore = window.sessionStorage).setItem(uid, uid);

    const localResult = localstore.getItem(uid);
    const sessionResult = sessionstore.getItem(uid);

    localstore.removeItem(uid);
    sessionstore.removeItem(uid);

    return !!localResult && !!localstore && !!sessionResult && !!sessionstore;
  } catch (_) {
    return false;
  }
})();

const SessionLockr = {
  get<D = any>(key: string) {
    if (!hasStorage) return;
    const item = sessionStorage.getItem(key);
    return !!item ? (JSON.parse(item) as D) : null;
  },

  set<D>(key: string, value: D) {
    if (!hasStorage) return;

    sessionStorage.setItem(key, JSON.stringify(value));

    // HACK: Previously we weren't careful about clearing data from localStorage.
    //       Mitigate that by cleaning it up now as we go instead.
    localStorage.removeItem(key);
  },

  rm(key: string) {
    if (!hasStorage) return;

    sessionStorage.removeItem(key);

    // HACK: Previously we weren't careful about clearing data from localStorage.
    //       Mitigate that by cleaning it up now as we go instead.
    localStorage.removeItem(key);
  },

  clear() {
    if (!hasStorage) return;

    sessionStorage.clear();
  },
} as const;

export default SessionLockr;
