import { createGlobalStyle } from 'styled-components/macro';

export const JoyrideStyles = createGlobalStyle`
  body.react-joyride__beacon-hidden {
    button.react-joyride__beacon {
      opacity: 0 !important;
      pointer-events: none;
    }
  }
`;
