import { Typography, TypographyProps, MosaiqComponentProps } from '@qatalog/mosaiq-ui';

import { useIsExtraSmallOnly } from 'mosaiq/hooks';

const OnboardingLabel = ({
  children,
  ...props
}: MosaiqComponentProps<
  Omit<TypographyProps, 'variant' | 'ellipsis' | 'fontWeight'>,
  HTMLDivElement
>) => {
  const isMobileView = useIsExtraSmallOnly();

  return (
    <Typography
      variant={isMobileView ? 'body3' : 'title5'}
      ellipsis={{ lines: 1 }}
      fontWeight={500}
      {...props}
    >
      {children}
    </Typography>
  );
};

export default OnboardingLabel;
