import styled from 'styled-components/macro';
import { Button, Flex, Toast } from '@qatalog/mosaiq-ui';

export const LoginContainer = styled.div`
  background: ${({ theme }) =>
    `linear-gradient(180deg, ${theme.mosaiq.colors.white} 32%, #F4F2F0 100%)`};
`;

export const LoginInner = styled.div`
  position: relative;
  width: 100%;
  height: 100vh;
  max-width: 1920px;
  margin: auto;
  overflow: hidden;
  display: flex;
  align-items: center;
`;

export const LoginContent = styled(Flex).attrs({
  wrap: 'wrap',
  justify: 'center',
})`
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 1600px;
  margin: auto;
  overflow: hidden;
`;

export const LoginLogo = styled.a`
  position: absolute;
  top: ${({ theme }) => theme.spacing(6)}px;
  left: ${({ theme }) => theme.spacing(7)}px;
  width: 128px;
  height: 50px;
  z-index: 10;

  svg,
  img {
    max-width: 100%;
    max-height: 100%;
  }

  ${({ theme }) => theme.mosaiq.mediaQueries.screenSize({ max: 'extraSmall' })`
    top: ${theme.spacing(4)}px;
    left: ${theme.spacing(4)}px;
  `};
`;

export const LoginHeader = styled.h2`
  font-family: ${({ theme }) => theme.mosaiq.fonts.main};
  font-weight: 500;
  font-size: 48px;
  line-height: 1;
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
  ${({ theme }) => theme.mosaiq.mediaQueries.screenSize({ max: 'extraSmall' })`
    font-size: 44px;
  `}
`;

export const LoginForm = styled.div`
  position: relative;
  margin: 0 ${({ theme }) => theme.spacing(20)}px;
  min-width: 380px;
  max-width: 380px;
  flex: 1;
  align-self: center;
  text-align: center;
  z-index: 5;

  ${Button} {
    width: 100%;

    & > span {
      width: 100%;
      display: block;
    }
  }

  ${({ theme }) => theme.mosaiq.mediaQueries.screenSize({ max: 'small' })`
    margin: 0 ${theme.spacing(6)}px;
  `};

  ${({ theme }) => theme.mosaiq.mediaQueries.screenSize({ max: 'extraSmall' })`
    margin: 0;
    padding: 0 ${theme.spacing(4)}px;
    min-width: 100%;
    max-width: 100%;
  `};
`;

export const LoginShadowButton = styled(Button)`
  box-shadow:
    0px 10px 20px 0px rgba(0, 0, 0, 0.006),
    0px 2px 4px 0px rgba(0, 0, 0, 0.006),
    0px 5px 10px 0px rgba(0, 0, 0, 0.006),
    0px 0px 10px 0px rgba(0, 0, 0, 0.006);
`;

export const FreeTrialToast = styled(Toast)`
  position: absolute;
  top: ${({ theme }) => theme.spacing(4)}px;
  left: 0;
  right: 0;
  margin: auto;
  z-index: 6;
  color: ${({ theme }) => theme.mosaiq.colors.success};
`;

export const LoginFooter = styled.div`
  position: fixed;
  bottom: ${({ theme }) => theme.spacing(3)}px;
  left: ${({ theme }) => theme.spacing(3)}px;
  right: ${({ theme }) => theme.spacing(3)}px;

  display: flex;
  align-items: center;
  justify-content: center;
`;
