import type { Sidebar } from './types';

export enum SidebarsActionType {
  SET = 'sidebars/set',
  DELETE = 'sidebars/delete',
  DELETE_ALL = 'sidebars/deleteAll',
  INTERCEPT_CLOSE = 'sidebar/interceptClose',
}

export interface SidebarState {
  sidebars: Sidebar[];
}

interface SidebarsAction {
  type: SidebarsActionType;
  sidebar: Sidebar;
  interceptor?: (() => boolean) | null;
}

const initialState: SidebarState = {
  sidebars: [],
};

export default function sidebarsReducer(state = initialState, action: SidebarsAction) {
  switch (action.type) {
    case SidebarsActionType.SET: {
      return {
        ...state,
        sidebars: [...state.sidebars, action.sidebar],
      };
    }

    case SidebarsActionType.DELETE: {
      const newSidebars = [...state.sidebars];

      newSidebars.pop();

      return {
        ...state,
        sidebars: newSidebars ?? [],
        interceptor: null,
      };
    }

    case SidebarsActionType.DELETE_ALL: {
      return {
        ...state,
        sidebars: [],
        interceptor: null,
      };
    }

    case SidebarsActionType.INTERCEPT_CLOSE: {
      return { ...state, interceptor: action.interceptor };
    }

    default:
      return state;
  }
}
