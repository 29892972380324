import { Button } from '@qatalog/mosaiq-ui';
import { Headset } from 'phosphor-react';

import useSupportAnalytics from 'analytics/hooks/support';

const OnboardingSupportBtn = () => {
  const { eventTypes, trackSupportEvent } = useSupportAnalytics();

  return (
    <Button
      variant="outline"
      elevation={1}
      startAdornment={<Headset />}
      href="https://help.qatalog.com/hc/en-us/requests/new"
      onClick={() => {
        trackSupportEvent(eventTypes.CONTACT_SUPPORT_CLICKED, {
          page: window.location.pathname,
        });
      }}
    >
      Contact Support
    </Button>
  );
};

export default OnboardingSupportBtn;
