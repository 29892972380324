import {
  FC,
  createContext,
  useReducer,
  useContext,
  Reducer,
  useCallback,
  PropsWithChildren,
} from 'react';
import { useHistory } from 'router';

import { useLockBodyScroll } from 'utils/overlays';

import { NOTIFICATIONS_URL_SEARCH_PARAM } from 'mosaiq/notifications';

export interface NotificationsSidebarState {
  open: boolean;
  newNotificationsCount: number; // This is used to show/hide the badge in the navigation
}

export interface NotificationsSidebarAction {
  type: NotificationsActionType;
  newNotificationsCount?: number;
}

export interface NotificationsSidebarContextValue extends NotificationsSidebarState {
  openNotificationsSidebar: () => void;
  closeNotificationsSidebar: () => void;
  updateNewNotificationsCount: (count: number) => void;
}

enum NotificationsActionType {
  OPEN_NOTIFICATIONS = 'openNotifications',
  CLOSE_NOTIFICATIONS = 'closeNotifications',
  UPDATE_NEW_NOTIFICATIONS_COUNT = 'updateNewNotificationsCount',
}

const initialState: NotificationsSidebarState = {
  open: false,
  newNotificationsCount: 0,
};

const notificationsSidebarReducer: Reducer<
  NotificationsSidebarState,
  NotificationsSidebarAction
> = (state, action) => {
  switch (action.type) {
    case NotificationsActionType.OPEN_NOTIFICATIONS:
      return {
        ...state,
        open: true,
      };

    case NotificationsActionType.CLOSE_NOTIFICATIONS:
      return {
        ...state,
        open: false,
      };

    default:
      return state;
  }
};

export const NotificationsSidebarContext = createContext<NotificationsSidebarContextValue>(
  {} as NotificationsSidebarContextValue,
);

export const useNotificationsSidebar = () => useContext(NotificationsSidebarContext);

export const NotificationsProvider: FC<PropsWithChildren> = ({ children }) => {
  const history = useHistory();

  const [state, dispatch] = useReducer(notificationsSidebarReducer, initialState);
  useLockBodyScroll(state.open);

  const closeNotificationsSidebar = useCallback(() => {
    const urlSearchParams = new URLSearchParams(history.location.search);

    if (urlSearchParams.has(NOTIFICATIONS_URL_SEARCH_PARAM)) {
      urlSearchParams.delete(NOTIFICATIONS_URL_SEARCH_PARAM);

      history.replace({
        pathname: history.location.pathname,
        search: urlSearchParams.toString(),
        state: history.location.state,
      });
    }

    dispatch({
      type: NotificationsActionType.CLOSE_NOTIFICATIONS,
    });
  }, [history]);

  const openNotificationsSidebar = useCallback(() => {
    dispatch({
      type: NotificationsActionType.OPEN_NOTIFICATIONS,
    });
  }, []);

  const updateNewNotificationsCount = useCallback((count: number) => {
    dispatch({
      type: NotificationsActionType.UPDATE_NEW_NOTIFICATIONS_COUNT,
      newNotificationsCount: count,
    });
  }, []);

  return (
    <NotificationsSidebarContext.Provider
      value={{
        ...state,
        closeNotificationsSidebar,
        openNotificationsSidebar,
        updateNewNotificationsCount,
      }}
    >
      {children}
    </NotificationsSidebarContext.Provider>
  );
};
