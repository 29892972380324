import React, { createContext, useContext } from 'react';
import axios from 'axios';

import config from 'config';
import { useAsyncReducer } from 'utils/hooks';

const initialState = {
  notifications: null,
  newNotificationsCount: 0, // This is used to show/hide the badge in the navigation
  snoozedNotificationKeys: [], // This tracks notifications that are unseen but not net-new
  moreNotifications: false, // This is used to stop the pagination on scroll
};

const setNotificationAsSeen = (keys) =>
  axios({
    url: config.envConfig.endpoints.notifications.seen,
    method: 'POST',
    data: {
      keys,
    },
  });

const notificationsReducer = (state, action) => {
  const reducerMap = {
    fetchNotifications: async (shouldManuallyOverride) => {
      const { offset = 0, pageSize = 20, shouldOverwrite, shouldMarkAsSeen } = action;

      try {
        const { data } = await axios({
          url: config.envConfig.endpoints.notifications.the,
          method: 'POST',
          data: {
            offset,
            limit: pageSize,
          },
        });

        const newNotifications =
          shouldOverwrite || shouldManuallyOverride
            ? data
            : [...(state.notifications || []), ...data];

        const unseenNotificationsKeys = newNotifications
          .filter(
            ({ data: { seen }, key }) => !seen && !state.snoozedNotificationKeys.includes(key),
          )
          .map(({ key }) => key);

        if (shouldMarkAsSeen && unseenNotificationsKeys.length) {
          await setNotificationAsSeen(unseenNotificationsKeys);
        }

        return {
          ...state,
          notifications: newNotifications,
          newNotificationsCount: !shouldMarkAsSeen ? unseenNotificationsKeys.length : 0,
          moreNotifications: !!data.length,
          error: null,
        };
      } catch (error) {
        return {
          ...state,
          notifications: null,
          newNotificationsCount: 0,
          moreNotifications: false,
          error,
        };
      }
    },
    setNotificationAsSeen: async () => {
      try {
        await setNotificationAsSeen(action.keys);
        const freshState = await reducerMap.fetchNotifications(true);

        return freshState;
      } catch (error) {
        return {
          ...state,
          error,
        };
      }
    },
    setAllNotificationsAsSeen: async () => {
      try {
        const unseenNotificationsKeys = state.notifications
          ?.filter(({ data: { seen } }) => !seen)
          ?.map(({ key }) => key);

        if (unseenNotificationsKeys?.length) {
          await setNotificationAsSeen(unseenNotificationsKeys);
          const freshState = await reducerMap.fetchNotifications(true);

          return freshState;
        }

        return state;
      } catch (error) {
        return {
          ...state,
          error,
        };
      }
    },
    snoozeAllNotifications: () => {
      const unseenNotificationsKeys = state.notifications
        ?.filter(({ data: { seen } }) => !seen)
        ?.map(({ key }) => key);

      return {
        ...state,
        snoozedNotificationKeys: unseenNotificationsKeys,
        newNotificationsCount: 0,
      };
    },
  };

  return reducerMap[action.type] ? reducerMap[action.type]() : state;
};

export const NotificationsContext = createContext([{}]);

export const NotificationsProvider = ({ children }) => {
  const [state, dispatch] = useAsyncReducer(notificationsReducer, initialState);

  return (
    <NotificationsContext.Provider value={[state, dispatch]}>
      {children}
    </NotificationsContext.Provider>
  );
};

export const useNotificationsState = () => useContext(NotificationsContext);
