import createStateContext from './state';
import { dispatchIntercept } from './middleware';

const initialState = {
  actions: null,
  navigation: [],
  isOverviewPage: false,
};

const updateHeader = ({ state, action }) => {
  const actions = action.actions === null ? null : action.actions || state.actions;

  return {
    ...state,
    actions,
    navigation: action.navigation || state.navigation,
  };
};

const updateOverviewPage = ({ state, action }) => {
  const isOverviewPage = action.isOverviewPage;

  return {
    ...state,
    isOverviewPage,
  };
};

const appHeaderReducer = (state, action) => {
  const reducerMap = {
    updateHeader,
    updateOverviewPage,
  };

  return reducerMap[action.type] ? reducerMap[action.type]({ state, action }) : state;
};

const { useStateValue, StateProvider, StateContext } = createStateContext(
  appHeaderReducer,
  initialState,
  dispatchIntercept,
);

const DISPLAY_NAME = 'AppHeaderState';

const useAppHeaderState = useStateValue;

const AppHeaderProvider = StateProvider;

StateContext.displayName = DISPLAY_NAME;

AppHeaderProvider.displayName = DISPLAY_NAME;

export { useAppHeaderState, AppHeaderProvider, StateContext };
