import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import { v4 as uuid } from 'uuid';

import { Sidebar } from './sidebarReducer';
export { default as SidebarContainer } from './SidebarContainer/SidebarContainer';
export { default as sidebarReducer } from './sidebarReducer';
export * from './sidebarReducer';

const selectSidebars = createSelector(
  (state: Record<string, any>) => state.sidebar,
  ({ sidebars }) => sidebars,
);

export const useSidebar = () => {
  const sidebars = useSelector(selectSidebars);

  const dispatch = useDispatch();

  const applyIds = useCallback((sidebar: Sidebar) => {
    const id = uuid();

    return {
      ...sidebar,
      id,
      paths: sidebar.paths?.map((path) => ({
        ...path,
        id: uuid(),
      })),
    };
  }, []);

  const openSidebar = useCallback(
    (sidebar: Sidebar) => {
      const sidebarWithId = applyIds(sidebar);
      const id = sidebarWithId?.id;

      dispatch({
        type: 'sidebar/open',
        sidebar: sidebarWithId,
      });

      sidebar?.onOpen?.();
      return id;
    },
    [applyIds, dispatch],
  );

  const openSidebarPath = useCallback(
    (sidebar: Sidebar, index: number) => {
      const sidebarWithId = applyIds(sidebar);
      const id = sidebarWithId?.id;

      dispatch({
        type: 'sidebar/openPath',
        sidebar: sidebarWithId,
        index,
      });

      sidebar?.onOpen?.();
      return id;
    },
    [applyIds, dispatch],
  );

  const updateSidebar = useCallback(
    (id: string, sidebar: Sidebar) => {
      dispatch({
        type: 'sidebar/update',
        sidebar: {
          id,
          ...sidebar,
        },
      });
    },
    [dispatch],
  );

  /**
   * Intercept calls to close a sidebar, in order to handle cleanup or to prevent it.
   *
   * @param interceptor is a function that is called before attempting to close
   * a sidebar. If it is falsy, no sidebars will be closed.
   */
  const interceptClose = useCallback(
    (interceptor?: () => boolean) => {
      dispatch({
        type: 'sidebar/interceptClose',
        interceptor,
      });
    },
    [dispatch],
  );

  const clearIntercept = useCallback(() => interceptClose(), [interceptClose]);

  const closeAllSidebars = useCallback(() => {
    dispatch({
      type: 'sidebar/closeAll',
    });
  }, [dispatch]);

  return {
    sidebars,
    openSidebar,
    openSidebarPath,
    updateSidebar,
    interceptClose,
    clearIntercept,
    closeAllSidebars,
  };
};
