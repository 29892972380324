export const convertArrayToMap = (array, key) =>
  array?.length
    ? array.reduce((map, obj) => {
        map[obj[key]] = obj;
        return map;
      }, {})
    : {};

export const getListFromContext = (list, filterPredicate = () => true) =>
  list?.data
    ? Object.keys(list.data)
        .map((id) => list.data[id])
        .filter(filterPredicate)
    : null;

// TODO: Remove once all entities use slugs
export const getEntityAccessor = (entityType) => {
  const accessorMap = {
    people: 'slug',
    teams: 'slug',
    workflows: 'slug',
  };

  return accessorMap[entityType] || 'id';
};

export const resetEntitiesState = ({ state, entityType, error }) => ({
  ...state,
  [entityType]: {
    data: null,
    meta: null,
    permissions: null,
    error,
  },
});

export const upsertEntitiesToState = ({ data, state, entityType, entities, shouldOverwrite }) => {
  const entityState = {};

  const key = getEntityAccessor(entityType);

  entityState.data = convertArrayToMap(entities, key);

  // Upsert new  data
  if (state[entityType] && !shouldOverwrite) {
    entityState.data = {
      ...state[entityType].data,
      ...entityState.data,
    };
  }

  // Upsert new meta data
  entityState.meta = data?.meta || data?.total?.aggregate || state?.[entityType]?.meta;

  // Upsert new permissions
  entityState.permissions = data?.permissions?.[0] || state?.[entityType]?.permissions?.[0];

  return {
    ...state,
    [entityType]: {
      ...state[entityType],
      ...entityState,
    },
  };
};

export const upsertEntityToState = ({ state, entityType, newEntity, key }) => ({
  ...state,
  [entityType]: {
    ...state[entityType],
    data: {
      ...(state[entityType] ? state[entityType].data : {}),
      [newEntity[key]]: newEntity,
    },
    error: null,
  },
});
