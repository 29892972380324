import SessionLockr from 'utils/session-storage';
import { APPSTATE_CONTEXT_KEY } from './constants';
import createStateContext from './state';
import { dispatchIntercept } from './middleware';
import analytics from 'analytics';

const getInitialState = () => {
  return {
    entityViews: {},
    navigationDrawerOpen: false,
  };
};

const restoreInitialState = () => SessionLockr.get(APPSTATE_CONTEXT_KEY) || getInitialState();

const appStateReducer = (state, action) => {
  const reducerMap = {
    addView: () => {
      const addViewStateUpdate = {
        ...state,
        entityViews: {
          ...state.entityViews,
          ...action.view,
        },
      };

      SessionLockr.set(APPSTATE_CONTEXT_KEY, addViewStateUpdate);
      return addViewStateUpdate;
    },
    logout: () => {
      SessionLockr.clear();
      analytics.track('Profile Signed Out');
      return getInitialState();
    },
    toggleNavigationDrawer: () => {
      const newAppState = {
        ...state,
        navigationDrawerOpen: !state.navigationDrawerOpen,
      };

      SessionLockr.set(APPSTATE_CONTEXT_KEY, newAppState);
      return newAppState;
    },
    openNavigationDrawer: () => {
      const newAppState = {
        ...state,
        navigationDrawerOpen: true,
      };

      SessionLockr.set(APPSTATE_CONTEXT_KEY, newAppState);
      return newAppState;
    },
    toggleNotificationDrawer: () => {
      const toggle = action.toggle;
      const newAppState = {
        ...state,
        notificationDrawerOpen:
          typeof toggle !== 'undefined' ? toggle : !state.notificationDrawerOpen,
      };

      SessionLockr.set(APPSTATE_CONTEXT_KEY, newAppState);
      return newAppState;
    },
    closeNotificationDrawer: () => {
      const newAppState = {
        ...state,
        notificationDrawerOpen: false,
      };
      SessionLockr.set(APPSTATE_CONTEXT_KEY, newAppState);
      return newAppState;
    },
    closeNavigationDrawer: () => {
      const newAppState = {
        ...state,
        navigationDrawerOpen: false,
      };

      SessionLockr.set(APPSTATE_CONTEXT_KEY, newAppState);
      return newAppState;
    },
    setViewProp: () => {
      const setViewStateUpdate = {
        ...state,
        entityViews: {
          ...state.entityViews,
          ...action.view,
        },
      };

      SessionLockr.set(APPSTATE_CONTEXT_KEY, setViewStateUpdate);
      return setViewStateUpdate;
    },
  };

  return reducerMap[action.type] ? reducerMap[action.type]() : state;
};

const { useStateValue, StateProvider, StateContext } = createStateContext(
  appStateReducer,
  restoreInitialState(),
  dispatchIntercept,
);

const DISPLAY_NAME = 'ViewsState';
const useAppState = useStateValue;
const AppStateProvider = StateProvider;

StateContext.displayName = DISPLAY_NAME;
AppStateProvider.displayName = DISPLAY_NAME;

export { useAppState, AppStateProvider, StateContext };
