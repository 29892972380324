import styled from 'styled-components/macro';

export const OnboardingInputField = styled.input`
  border: 0;
  outline: 0;
  padding: 0;
  width: 100%;
  background-color: transparent;
  color: ${({ theme }) => theme.mosaiq.colors.title};
  ${({ theme }) => theme.mosaiq.typography.title1({ singleLine: true, fontWeight: 500 })}
  caret-color: ${({ theme }) => theme.mosaiq.colors.action};

  &::placeholder {
    color: ${({ theme }) => theme.mosaiq.colors.placeholder};
  }

  ${({ theme }) => theme.mosaiq.mediaQueries.screenSize({ max: 'extraSmall' })`
      ${theme.mosaiq.typography.title3({ singleLine: true, fontWeight: 500 })}
      padding: ${theme.mosaiq.spacing(1)}px 0px; 
      border-bottom: 1px solid ${theme.mosaiq.colors.grey60};
  `};
`;
