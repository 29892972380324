import React, { useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { ArrowRight } from 'phosphor-react';
import { Typography, Button, Flex } from '@qatalog/mosaiq-ui';
import { useAnalytics } from 'analytics';
import { useEffectOnce } from 'hooks';

import config from 'config';

import {
  OnboardingContainer,
  OnboardingInput,
  OnboardingLogo,
  OnboardingStepsContent,
} from 'mosaiq/signup/components';

import { ReactComponent as QMonogramWithTextDark } from 'assets/icons/q-monogram-with-text-dark.svg?react';
import { OnboardingActions } from '../Onboarding.styles';

type FormFields = {
  okta_domain: string;
  okta_client_id: string;
  okta_client_secret: string;
};

const OKTA_DOMAIN_PATTERN =
  /^(?!.{256})(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.){1,}[a-z]{1,63}$/i;

const OnboardingOkta = ({ rememberMe }: { rememberMe?: boolean }) => {
  const { trackPageView, trackEvent, eventTypes } = useAnalytics();

  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { isValid },
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      okta_domain: '',
      okta_client_id: '',
      okta_client_secret: '',
    },
  });

  const onSubmit: SubmitHandler<FormFields> = ({
    okta_domain,
    okta_client_id,
    okta_client_secret,
  }) => {
    setIsLoading(true);

    trackEvent(eventTypes.signup.CONTINUE_WITH_OKTA_CLICKED);

    window.location.href = `${
      config.envConfig.endpoints.auth.oktaOAuth
    }?ci=${okta_client_id}&cs=${okta_client_secret}&d=${okta_domain}${
      rememberMe ? '&persist=true' : ''
    }`;
  };

  const qatalogRootUrl: string = useMemo(() => {
    const [part1, part2, part3] = window.location.host.split('.');

    return !part3
      ? `${window.location.protocol}//${part1}.${part2}`
      : `${window.location.protocol}//${part2}.${part3}`;
  }, []);

  useEffectOnce(() => {
    trackPageView(eventTypes.signup.CONTINUE_WITH_OKTA_CLICKED);
  });

  return (
    <OnboardingContainer
      onSubmit={handleSubmit(onSubmit)}
      data-testid="onboardingOktaSetupContainer"
    >
      <OnboardingLogo href={qatalogRootUrl} title="Back to homepage">
        <QMonogramWithTextDark />
      </OnboardingLogo>
      <OnboardingStepsContent isFullView>
        <Flex direction="column" gap={6}>
          <Typography variant="body2" color="body">
            If you haven’t already done so,{' '}
            <Typography
              element="a"
              variant="body2"
              color="action"
              // @ts-ignore
              href="https://www.okta.com/integrations/qatalog"
              target="_blank"
              rel="noopener noreferrer"
            >
              add the Qatalog integration
            </Typography>{' '}
            to your organization’s Okta instance.
          </Typography>
          <OnboardingInput
            ref={register({
              required: true,
              pattern: OKTA_DOMAIN_PATTERN,
            })}
            type="text"
            name="okta_domain"
            label="What is your Okta domain?"
            placeholder="yoursubdomain.okta.com"
            autoFocus
            disabled={isLoading}
          />
          <OnboardingInput
            ref={register({ required: true })}
            type="text"
            name="okta_client_id"
            label="What is your Okta Qatalog client ID?"
            placeholder="client ID"
            autoFocus={false}
            disabled={isLoading}
          />
          <OnboardingInput
            ref={register({ required: true })}
            type="password"
            name="okta_client_secret"
            label="What is your Okta Qatalog client secret?"
            placeholder="client secret"
            autoFocus={false}
            disabled={isLoading}
          />
        </Flex>
      </OnboardingStepsContent>
      <OnboardingActions>
        <Button
          endAdornment={<ArrowRight />}
          onClick={handleSubmit(onSubmit)}
          disabled={!isValid || isLoading}
          size="large"
        >
          Continue
        </Button>
      </OnboardingActions>
    </OnboardingContainer>
  );
};

export default OnboardingOkta;
