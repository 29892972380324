import { SyntheticEvent, useMemo, useState } from 'react';
import { ArrowRight } from 'phosphor-react';
import { Typography, Button } from '@qatalog/mosaiq-ui';
import { useAnalytics } from 'analytics';
import { useEffectOnce } from 'hooks';

import config from 'config';

import { isPersonalEmail, validateEmail } from 'utils/validations';

import { ReactComponent as QMonogramWithTextDark } from 'assets/icons/q-monogram-with-text-dark.svg?react';

import {
  OnboardingContainer,
  OnboardingInput,
  OnboardingLogo,
  OnboardingStepsContent,
} from 'mosaiq/signup/components';

import { OnboardingActions } from '../Onboarding.styles';

const OnboardingEmail = ({
  rememberMe,
  companyOnly,
}: {
  rememberMe?: boolean;
  companyOnly: boolean;
}) => {
  const { trackPageView, trackEvent, eventTypes } = useAnalytics();

  const [email, setEmail] = useState<string>('');

  const isCompanyEmail = !isPersonalEmail(email);
  const isValidEmail = validateEmail(email) && companyOnly ? isCompanyEmail : true;

  const qatalogRootUrl: string = useMemo(() => {
    const [part1, part2, part3] = window.location.host.split('.');

    return !part3
      ? `${window.location.protocol}//${part1}.${part2}`
      : `${window.location.protocol}//${part2}.${part3}`;
  }, []);

  const handleSubmit = async (event: SyntheticEvent) => {
    event.preventDefault();

    if (!isValidEmail) return;

    trackEvent(eventTypes.signup.VISIT_CONTINUE_WITH_EMAIL_PAGE);
    const encoded_email = encodeURIComponent(email);

    window.location.href = `${config.envConfig.endpoints.auth.email}?address=${encoded_email}${
      rememberMe ? '&persist=true' : ''
    }`;
  };

  useEffectOnce(() => {
    trackPageView(eventTypes.signup.VISIT_CONTINUE_WITH_EMAIL_PAGE);
  });

  return (
    <OnboardingContainer onSubmit={handleSubmit}>
      <OnboardingLogo href={qatalogRootUrl} title="Back to homepage">
        <QMonogramWithTextDark />
      </OnboardingLogo>
      <OnboardingStepsContent>
        <div>
          <OnboardingInput
            data-testid="onboardingEmailInputMessage"
            label={companyOnly ? 'Please enter your work email' : 'Please enter your email'}
            placeholder="jana@ollie.com"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            value={email}
            type="email"
            maxLength={250}
          />
          {isValidEmail && (
            <Typography
              data-testid="onboardingEmailMagicLinkMessage"
              variant="body2"
              marginTop={1.5}
              color="action"
            >
              We'll send you a magic link
            </Typography>
          )}
          {companyOnly && !isCompanyEmail && (
            <Typography
              data-testid="onboardingEmailMagicLinkMessage"
              variant="body2"
              marginTop={1.5}
              color="error"
            >
              It looks like you used a personal email. Please sign up with your work email address.
            </Typography>
          )}
        </div>
      </OnboardingStepsContent>
      <OnboardingActions>
        <Button
          endAdornment={<ArrowRight />}
          onClick={handleSubmit}
          disabled={!isValidEmail}
          size="large"
          data-testid="onboardingSendLinkBtn"
        >
          Send link
        </Button>
      </OnboardingActions>
    </OnboardingContainer>
  );
};

export default OnboardingEmail;
