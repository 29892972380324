const UTM_PARAMS = ['utm_source', 'utm_medium', 'utm_campaign', 'utm_keyword', 'utm_content'];

const getUtmParams = () => {
  const params = new URLSearchParams(window.location.search);

  return UTM_PARAMS.reduce((acc, param) => {
    if (params.has(param)) {
      return { ...acc, [param]: params.get(param) };
    }

    return acc;
  }, {});
};

export default getUtmParams;
