import slugify from 'slugify';

import { getEntityPermissions } from 'utils/permissions';

import getUtmParams from './utm-params';

const getUserAnalyticsPayload = (metadataState = {}, entity) => {
  const { person, org } = metadataState;

  const { permissions } = getEntityPermissions({
    accessEntityId: person.id,
    entity: entity,
  });

  return {
    permission_role: permissions?.role || null,
    department: person.department?.name || null,
    office_location: person.location?.city ? slugify(person.location.city, '_') : null,
    direct_reports: person.lead_id ? 'y' : 'n',
    account_size: org?.person_count,
    ...getUtmParams(),
  };
};

export default getUserAnalyticsPayload;
