import React from 'react';
import { captureErrorOnSentry } from 'utils/errors';
import ErrorCard from 'components/ErrorCard';

interface ReactError extends Error {
  cause?: Error;
}

interface ErrorBoundaryState {
  hasError: boolean;
  error?: ReactError;
  componentStack?: React.ErrorInfo['componentStack'];
}

class ErrorBoundary extends React.Component<
  { children: React.ReactNode; fallback?: React.ReactNode; key?: React.Key },
  ErrorBoundaryState
> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error: ReactError, { componentStack }: React.ErrorInfo) {
    const errorBoundaryError = new Error(error.message);
    errorBoundaryError.name = `React ErrorBoundary ${errorBoundaryError.name}`;
    errorBoundaryError.stack = componentStack || undefined;

    error.cause = errorBoundaryError;

    captureErrorOnSentry(error, { react: componentStack });
    this.setState({ hasError: true, error, componentStack });
  }

  render() {
    if (this.state.hasError) {
      if (this.props.fallback !== undefined) {
        return this.props.fallback;
      }

      return (
        <div key={this.props.key} style={{ padding: 20 }}>
          <ErrorCard error={this.state.error} />
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
