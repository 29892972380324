import React from 'react';
import Lockr from 'lockr';
import SessionLockr from 'utils/session-storage';

import { AppStateProvider } from './appStateContext';
import { EntitiesProvider } from './entitiesContext';
import { IntegrationsProvider } from '../integrations';
import { NotificationsProvider } from './notificationsContext';
import { AppHeaderProvider } from './appHeaderContext';

import { SCHEMA_VERSION_KEY, APPSTATE_CONTEXT_KEY, SEARCH_CONTEXT_KEY } from './constants';

const SCHEMA_VERSION = 1;

const CURRENT_STORE_VERSION = Lockr.get(SCHEMA_VERSION_KEY);

if (CURRENT_STORE_VERSION !== SCHEMA_VERSION) {
  Lockr.rm(SEARCH_CONTEXT_KEY);
  SessionLockr.rm(APPSTATE_CONTEXT_KEY);
  Lockr.set(SCHEMA_VERSION_KEY, SCHEMA_VERSION);
}

const LocalState = ({ children }) => {
  return (
    <AppStateProvider>
      <AppHeaderProvider>
        <EntitiesProvider>
          <NotificationsProvider>
            <IntegrationsProvider>{children}</IntegrationsProvider>
          </NotificationsProvider>
        </EntitiesProvider>
      </AppHeaderProvider>
    </AppStateProvider>
  );
};

export default LocalState;
