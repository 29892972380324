import isElectron from 'is-electron';
import { useMatch, redirect, Route, Routes } from 'router';

import { MarketingDataProvider } from 'store/marketingDataContext';
import { useMetadataState } from 'store/metadata';

import Error from 'mosaiq/components/Error';

import LoadingApp from 'components/LoadingApp/LoadingApp';

import { Login } from './views';

const ComponentToRender = () => {
  const [metadata] = useMetadataState<'unauthed'>();

  if (metadata.is_authenticated === false) {
    return <Login />;
  }

  return <LoadingApp />;
};

const UnauthedApp = () => {
  const [metadata] = useMetadataState<'unauthed'>();

  const match = useMatch('/login');

  if ((!metadata.person_id || metadata.is_invited || isElectron()) && !match) {
    redirect('/login');
  }

  return (
    <MarketingDataProvider>
      <Routes>
        <Route path="/login" element={<ComponentToRender />} />
        <Route path="/" element={<ComponentToRender />} />
        <Route path="/error" element={<Error />} />
        <Route path="*" element={<Error />} />
      </Routes>
    </MarketingDataProvider>
  );
};

export default UnauthedApp;
