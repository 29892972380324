import { useEffect, ReactNode, useReducer, useCallback } from 'react';
import { ScrollLockContext, isLocked, lockBody, scrollLockReducer, unlockBody } from './overlays';

const ScrollLockProvider = ({ children }: { children?: ReactNode }) => {
  const [locks, dispatch] = useReducer(scrollLockReducer, new Set<string>());

  const acquire = useCallback((id: string) => dispatch({ acquire: id }), []);
  const release = useCallback((id: string) => dispatch({ release: id }), []);

  useEffect(() => {
    if (!!locks.size && !isLocked()) {
      lockBody();
    }

    if (!locks.size && isLocked()) {
      unlockBody();
    }
  }, [locks]);

  return (
    <ScrollLockContext.Provider value={{ locks, acquire, release }}>
      {children}
    </ScrollLockContext.Provider>
  );
};

export default ScrollLockProvider;
