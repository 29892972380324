import React, { ReactElement } from 'react';
import { toast, Slide, Zoom, Flip, Bounce, ToastOptions, Id as ToastId } from 'react-toastify';
import { Button, Toast } from '@qatalog/mosaiq-ui';

let singletonNotifications = new Set<string>();

const transitionMap = {
  slide: Slide,
  zoom: Zoom,
  flip: Flip,
  bounce: Bounce,
};

export type NotificationParameters = {
  id?: ToastId | undefined;
  content: ReactElement | string;
  icon?: ReactElement;
  config?: ToastOptions;
  cta?: { label: string; onClick: (e: MouseEvent) => void };
  transition?: keyof typeof transitionMap;
  type?: 'success' | 'info' | 'warn' | 'error';
};

export const showInAppNotification = ({
  config = {},
  content,
  cta,
  icon,
  transition = 'slide',
  type = 'info',
}: NotificationParameters) =>
  toast[type](
    <Toast
      message={content}
      icon={icon}
      primaryAction={
        cta ? (
          <Button
            variant="primary"
            size="small"
            onClick={(...args: any[]) => {
              // @ts-ignore
              cta.onClick?.(...args);
              // @ts-ignore
              toast.done();
            }}
          >
            {cta.label}
          </Button>
        ) : undefined
      }
    />,
    {
      transition: transitionMap[transition],
      ...config,
    },
  );

export const showInAppNotificationOnce = (key: string, notification: NotificationParameters) => {
  if (key && singletonNotifications.has(key)) return;

  singletonNotifications.add(key);
  showInAppNotification(notification);
};

export const errorNotification = ({ config, ...rest }: NotificationParameters) =>
  showInAppNotification({
    type: 'error',
    config: {
      autoClose: false,
      position: 'bottom-left',
      ...config,
    },
    ...rest,
  });

export const updateInAppNotification = ({
  config,
  content,
  id,
  cta = undefined,
  icon = undefined,
  transition = 'bounce',
  type = 'info',
}: NotificationParameters & { id: ToastId }) =>
  toast.update(id, {
    type: type === 'warn' ? 'warning' : type,
    render: (
      <Toast
        message={content}
        icon={icon}
        primaryAction={
          cta ? (
            <Button
              variant="primary"
              size="small"
              onClick={(...args: any[]) => {
                // @ts-ignore
                cta.onClick?.(...args);
                // @ts-ignore
                toast.done();
              }}
            >
              {cta.label}
            </Button>
          ) : undefined
        }
      />
    ),
    transition: transitionMap[transition],
    ...config,
  });

const updateBrowserIcon = (cdnUrl: string) => {
  const icons = document.querySelectorAll<HTMLLinkElement>('[rel="icon"]');

  Array.from(icons).forEach((icon) => {
    const [size] = icon.sizes?.[0]?.split?.('x') ?? [];
    icon.href = `${cdnUrl}/logo${size ?? 128}.png`;
  });
};

export const updateNotificationsCountInBrowserTab = ({
  cdnUrl,
  count,
}: {
  cdnUrl: string;
  count: number;
}) => {
  const originalTitle = 'Qatalog';

  if (count === 0) {
    updateBrowserIcon(cdnUrl);
    document.title = originalTitle;
    // @ts-ignore
    navigator.clearAppBadge && navigator.clearAppBadge();
    return;
  }

  updateBrowserIcon(cdnUrl);
  document.title = `(${count}) ${originalTitle}`;
  // @ts-ignore
  navigator.setAppBadge && navigator.setAppBadge(count);
};
